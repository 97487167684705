import * as React from 'react';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const VALUES = [
  { name: 'Target websites', id: true },
  { name: 'Exclude websites', id: false },
];

const LineitemTargetingSitePlacementTargetingIncludePlacements = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledRadioGroup
    name='targeting.sitePlacementTargeting.includePlacement'
    values={VALUES}
    valueType='bool'
    helper='Applies to both domains and targeting file'
    {...props}
  />
);

LineitemTargetingSitePlacementTargetingIncludePlacements.displayName =
  'Lineitem.targeting.sitePlacementTargeting.includePlacement';

export { LineitemTargetingSitePlacementTargetingIncludePlacements };
