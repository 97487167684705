export const DASHBOARD = '/';
export const LOGIN = '/login';

export const CAMPAIGN_LIST = '/campaign';
export const CAMPAIGN_CREATE = '/campaign/create';
export const CAMPAIGN_SHOW = '/campaign/:campaignId(\\d+)';
export const CAMPAIGN_EDIT = CAMPAIGN_SHOW + '/edit';
export const CAMPAIGN_STATISTICS_APPS = CAMPAIGN_SHOW + '/apps';
export const CAMPAIGN_STATISTICS_WEBSITES = CAMPAIGN_SHOW + '/websites';

export const CREATIVE_LIST = CAMPAIGN_SHOW + '/creative';
export const CREATIVE_CREATE = CAMPAIGN_SHOW + '/creative/create';
export const CREATIVE_SHOW = CAMPAIGN_SHOW + '/creative/:creativeId';
export const CREATIVE_EDIT = CAMPAIGN_SHOW + '/creative/:creativeId/edit';
export const CREATIVE_PREVIEW =
  CAMPAIGN_SHOW + '/creative/:creativeId/livepreview';

export const LINEITEM_LIST = CAMPAIGN_SHOW + '/lineitem';
export const LINEITEM_CREATE = CAMPAIGN_SHOW + '/lineitem/create';
export const LINEITEM_SHOW = CAMPAIGN_SHOW + '/lineitem/:lineitemId';
export const LINEITEM_EDIT = CAMPAIGN_SHOW + '/lineitem/:lineitemId/edit';

export const ORDER_LIST = '/orders';

export const TARGETING_LIST = '/targeting';
export const TARGETING_CREATE = '/targeting/create';
export const TARGETING_SHOW = '/targeting/:targetingId(\\d+)';
export const TARGETING_EDIT = '/targeting/:targetingId(\\d+)/edit';

export const MODELSET_LIST = '/modelset';
export const MODELSET_CREATE = '/modelset/create';
export const MODELSET_SHOW = '/modelset/:modelsetId(\\d+)';
export const MODELSET_EDIT = '/modelset/:modelsetId(\\d+)/edit';

export const OPTIMIZATION_LIST = '/optimization';
export const OPTIMIZATION_SHOW = '/optimization/:optimizationId(\\d+)';
export const OPTIMIZATION_EDIT = '/optimization/:optimizationId(\\d+)/edit';

export const ADVERTISER_LIST = '/advertiser';
export const ADVERTISER_CREATE = '/advertiser/create';
export const ADVERTISER_SHOW = '/advertiser/:advertiserId(\\d+)';
export const ADVERTISER_EDIT = '/advertiser/:advertiserId(\\d+)/edit';

export const DEAL_LIST = '/deal';
export const DEAL_SHOW = '/deal/:dealId(\\d+)';

export const PROPOSAL_LIST = '/proposal';
export const PROPOSAL_SHOW = '/proposal/:proposalId(\\d+)';
