import React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';

export const CampaignIabCategory = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const iabCategories = useAppSelector(state => state.consts.iabCategories);

  const options = React.useMemo(() => {
    if(!iabCategories) return;
    return iabCategories.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [iabCategories]);

  return (
    <ControlledSelect
      name='iabCategory'
      label='IAB Category'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};
