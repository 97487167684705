import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import {
  Box,
  Grid,
  Flex,
  Checkbox,
  Button,
  Alert,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { selectAllDevices, selectDevicesEntities } from 'store/devices';
import useIsSelected from '../useIsSelected';
import withFilteringInput from 'hooks/withFilteringInput';
import { DevicesWithFilteringInput } from './Devices';
import { BRANDS_FIELD_NAME, DEVICES_FIELD_NAME } from '../consts';
import { useFormContext, useController } from 'react-hook-form';
interface BrandCheckboxProps {
  name: string;
  id: number;
  showDevices: any;
}

const BrandCheckbox = ({ name, id }: BrandCheckboxProps): JSX.Element => {
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const { isSelected, handleSelectionChange } = useIsSelected(
    BRANDS_FIELD_NAME,
    id,
  );
  const { control } = useFormContext();

  const {
    field: { value: allSelectedDevices },
  } = useController({
    name: DEVICES_FIELD_NAME,
    control,
  });

  const allDevicesEntities = useAppSelector(selectDevicesEntities);

  const handleClick = () => {
    if (!isIndeterminate) handleSelectionChange();
  };

  useEffect(() => {
    let hasDevicesSelected = false;
    if (allSelectedDevices) {
      for (const deviceId of allSelectedDevices) {
        if (allDevicesEntities[deviceId]?.brandId === id) {
          hasDevicesSelected = true;
          break;
        }
      }
    }

    if (hasDevicesSelected) {
      if (!isIndeterminate) setIsIndeterminate(true);
      if (isSelected) handleSelectionChange();
    } else {
      if (isIndeterminate) setIsIndeterminate(false);
    }
  }, [allSelectedDevices, allDevicesEntities]);

  return (
    <>
      <Checkbox
        w='100%'
        isIndeterminate={isIndeterminate}
        isChecked={isSelected}
        onChange={handleClick}
      >
        {name}
      </Checkbox>
    </>
  );
};

interface BrandProps {
  name: string;
  id: number;
}

const Brand = ({ name, id }: BrandProps): JSX.Element => {
  const allDevices = useAppSelector(selectAllDevices);
  const [showDevices, handleShowDevices] = useBoolean();
  const [brandDevices, setBrandDevices] = useState<any[]>([]);

  useEffect(() => {
    if (!brandDevices.length) {
      const filteredDevices: any[] = allDevices.filter(
        device => device.brand?.id === id,
      );
      if (filteredDevices.length) setBrandDevices(filteredDevices);
    }
  }, [brandDevices.length, allDevices]);

  return (
    <>
      <Box position='relative'>
        <BrandCheckbox id={id} name={name} showDevices={showDevices} />
        <Flex
          position='absolute'
          p={1}
          top={0}
          right={0}
          height='100%'
          justifyContent='center'
          direction='column'
        >
          <Button
            isDisabled={!brandDevices.length}
            size='xs'
            w='15ch'
            onClick={handleShowDevices.toggle}
          >
            {showDevices ? 'Hide' : 'Show'} devices
          </Button>
        </Flex>
      </Box>
      {showDevices && (
        <Box ml={10} overflow='auto'>
          {brandDevices.length ? (
            <>
              <Text fontSize='xs' mb={2}>
                Selecting specific device disables targeting for the entire
                brand.
              </Text>
              <DevicesWithFilteringInput
                brandName={name}
                array={brandDevices}
                showUnfilteredResults
                filteredKey='name'
                minStringLengthRequired={3}
                size='sm'
              />
            </>
          ) : (
            <Alert status='info'>No devices found</Alert>
          )}
        </Box>
      )}
    </>
  );
};

interface BrandsProps {
  data: any[];
}

export const Brands = ({ data }: BrandsProps): JSX.Element => {
  return (
    <Box>
      <Grid gap={2} maxHeight='40ch' overflowX='auto' overflowY='scroll'>
        {data.map((brand: any) => (
          <Brand key={brand.id} {...brand} />
        ))}
      </Grid>
    </Box>
  );
};

interface BrandsFromSelectorProps {
  selector: () => void;
}

export const BrandsFromSelector = ({
  selector,
}: BrandsFromSelectorProps): JSX.Element => {
  const brands = useAppSelector(selector);
  return <Brands data={brands} />;
};

export const BrandsWithFilteringInput = withFilteringInput(Brands, 'data');
export default Brands;
