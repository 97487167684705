import * as yup from 'yup';
import { SelectItemType } from './consts.types';

type ApiGroupType = {
  id: number;
};

type ApiParameterType = {
  groupId: number;
  orderInGroup?: number;
  type: string;
  subType?: string;
  name: string;
  label?: string;
  tip?: string;
  checkedLabel?: string;
  uncheckedLabel?: string;
  default?: string | number;
  required?: boolean;
  maxLen?: number;
  precision?: number;
  min?: number;
  max?: number;
  maxElements?: number;
  autoComplete?: boolean;
  pattern?: string;
  placeHolder?: string;
  accept?: string;
  multiple?: boolean;
  minSize?: number;
  maxSize?: number;
  items: SelectItemType[];
};

/**
 * tworzy obiekt walidacji YUP na bazie tablicy parametrów przychodzących z API
 */
export function generateCreativeTemplateValidationSchema(
  parameters: ApiParameterType[],
): yup.AnyObjectSchema {
  const parametersSchema = parameters.reduce(
    (
      templateSchema: {
        [key: string]: any;
      },
      parameter: ApiParameterType,
    ) => {
      let fieldSchema;
      let defaultValue;
      switch (parameter.type) {
        case 'text':
          switch (parameter.subType) {
            /**
             * input text
             */
            case 'text':
              fieldSchema = yup
                .string()
                .default(
                  typeof parameter.default === 'string'
                    ? parameter.default
                    : '',
                );
              if (parameter.maxLen) {
                fieldSchema = fieldSchema.max(parameter.maxLen);
              }

              if (parameter.maxElements) {
                fieldSchema = yup.array(fieldSchema).max(parameter.maxElements);
              }
              break;
            /**
             * input number
             */
            case 'number':
              fieldSchema = yup
                .number()
                .default(
                  typeof parameter.default === 'number' ? parameter.default : 0,
                );
              if (!parameter.precision) fieldSchema = fieldSchema.integer();
              if (parameter.min) fieldSchema = fieldSchema.min(parameter.min);
              if (parameter.max) fieldSchema = fieldSchema.min(parameter.max);
              if (parameter.maxElements) {
                fieldSchema = yup.array(fieldSchema).max(parameter.maxElements);
              }
              break;
            default:
              break;
          }
          break;
        /**
         * checkbox
         */
        case 'bool':
          fieldSchema = yup
            .bool()
            .default(
              typeof parameter.default === 'boolean'
                ? parameter.default
                : false,
            );
          break;
        /**
         * select
         */
        case 'dropdown':
          fieldSchema = yup.mixed().default(parameter.default);
          break;
        case 'multidropdown':
          /**
           * select multiple
           */
          defaultValue = Array.isArray(parameter.default)
            ? parameter.default
            : [parameter.default];
          fieldSchema = yup.array(yup.mixed()).default(defaultValue);
          break;
        case 'longtext': {
          defaultValue =
            typeof parameter.default === 'string' ? parameter.default : '';
          fieldSchema = yup.string();
          if (parameter.maxLen) {
            fieldSchema = fieldSchema.min(parameter.maxLen);
          }
          if (parameter.maxElements) {
            fieldSchema = yup
              .array(fieldSchema)
              .max(parameter.maxElements)
              .default([defaultValue]);
          } else {
            fieldSchema = fieldSchema.default(defaultValue);
          }
          break;
        }

        case 'file':
          fieldSchema = yup.number().integer();
          break;
        default:
          break;
      }

      if (!fieldSchema) return templateSchema;

      if (parameter.required) {
        fieldSchema = fieldSchema.required();
      }

      fieldSchema = fieldSchema.label(parameter.label || parameter.name);

      templateSchema[parameter.name] = fieldSchema;
      return templateSchema;
    },
    {},
  );

  const validationSchema = yup.object().shape({
    parameters: yup.object().shape(parametersSchema), // endpoint api dla tworzenia i edycji kreacji wymaga, by parametry były zgrupowane w obiekcie parameters
  });

  return validationSchema;
}

/**
 * przetwarza listę grup i parametrów w api w fieldsety
 * sortuje parametry oraz standaryzuje klucze parametrów, dopasowując je do propów wymaganych przez odpowiadające parametrom komponenty
 * @see https://wiki.spicymobile.pl/wiki/projektprofilowanie/view/Dokumentacja%20modu%C5%82%C3%B3w/Dokumentacja%20szablon%C3%B3w%20kreacji/
 */
export function generateCreativeTemplateFieldsets(
  groups: ApiGroupType[],
  parameters: ApiParameterType[],
): Array<Record<string, any>> {
  const fieldsets = groups.map(group => {
    const params = parameters
      .filter(parameter => parameter.groupId === group.id)
      .sort((a, b) => {
        if (!a.orderInGroup || !b.orderInGroup) return 0;
        return a.orderInGroup - b.orderInGroup;
      }) // sortowanie rosnąco po wartości parametru orderInGroup
      .map(parameter => {
        /**
         * atrybuty wspólne dla każdego typu parametru
         */
        const baseValues = {
          type: parameter.type,
          label: parameter.label,
          name: `parameters.${parameter.name}`, // endpoint api dla tworzenia i edycji kreacji wymaga, by parametry były zgrupowane w obiekcie parameters
          helper: parameter.tip,
        };
        switch (parameter.type) {
          case 'bool':
            return {
              ...baseValues,
              checkedLabel: parameter.checkedLabel || 'Active',
              uncheckedLabel: parameter.uncheckedLabel || 'Inactive',
            };
          case 'dropdown':
          case 'multidropdown':
            return {
              ...baseValues,
              options: parameter.items,
            };
          case 'text':
            switch (parameter.subType) {
              case 'text':
                return {
                  ...baseValues,
                  subType: parameter.subType,
                  autoComplete: parameter.autoComplete,
                  pattern: parameter.pattern,
                  placeholder: parameter.placeHolder,
                  maxElements: parameter.maxElements,
                };
              case 'number':
                return {
                  ...baseValues,
                  subType: parameter.subType,
                  max: parameter.max,
                  min: parameter.min,
                  precision: parameter.precision,
                  maxElements: parameter.maxElements,
                };
              default:
                return {
                  ...baseValues,
                  subType: parameter.subType,
                  maxElements: parameter.maxElements,
                };
            }
          case 'longtext':
            return {
              ...baseValues,
              maxElements: parameter.maxElements,
            };
          case 'file':
            return {
              ...baseValues,
              accept: parameter.accept,
              minSize: parameter.minSize,
              maxSize: parameter.maxSize,
            };
          default:
            return baseValues;
        }
      });

    return {
      ...group,
      params,
    };
  });

  return fieldsets;
}
