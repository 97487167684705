import * as React from 'react';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import { FiPlay, FiPause } from 'react-icons/fi';
import { useAppDispatch, userHasRequiredPermissions } from 'hooks';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';

export type ToggleStatusIconButtonProps = {
  toggleAction: () => AsyncThunkAction<any, any, any>;
  entityName: string;
  isActive: boolean;
};

export const ToggleStatusIconButton = ({
  entityName,
  isActive,
  toggleAction,
  ...props
}: ToggleStatusIconButtonProps & IconButtonProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const showToast = useToast();
  const [isPending, setIsPending] = React.useState(false);
  const userHasPermissions = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);

  if (!userHasPermissions) return null;

  const label = `Set ${entityName} as ${isActive ? 'inactive' : 'active'}`;
  const successMessage = `${entityName} is now ${
    isActive ? 'inactive' : 'active'
  }`;

  async function handleButtonClick(): Promise<any> {
    setIsPending(true);
    try {
      const response = unwrapResult(await dispatch(toggleAction()));
      if (response?.error) {
        throw new Error(response.error);
      }

      showToast({
        title: 'Status has been changed',
        description: successMessage,
        position: 'bottom',
        status: isActive ? 'info' : 'success',
        duration: 3000,
      });
    } catch (error: any) {
      const message = error?.data?.message;
      showToast({
        title: 'Something went wrong',
        description: message || 'Please try again later',
        position: 'bottom',
        status: 'error',
        isClosable: true,
      });
    }
    setIsPending(false);
  }

  return (
    <Tooltip label={label} placement='top' hasArrow>
      <IconButton
        aria-label={label}
        icon={isActive ? <FiPause /> : <FiPlay />}
        isLoading={isPending}
        onClick={handleButtonClick}
        {...props}
      />
    </Tooltip>
  );
};
