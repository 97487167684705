import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './proposals.adapter';
import { REDUCER_KEY } from './proposals.consts';
import { ProposalParams } from './proposals.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): ProposalParams | null => selectors.selectById(state, id) || null;

export const selectCurrentProposalId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllProposals = createSelector(
  state => selectors.selectAll(state),
  proposal => proposal || [],
);

export const selectUnspecifiedProposals = createSelector(
  state => selectAllProposals(state),
  deals =>
    deals.filter(deal => deal.state.toLowerCase().includes('unspecified')),
);

export const selectTerminatedProposals = createSelector(
  state => selectAllProposals(state),
  deals =>
    deals.filter(deal => deal.state.toLowerCase().includes('terminated')),
);

export const selectFinalizedProposals = createSelector(
  state => selectAllProposals(state),
  deals => deals.filter(deal => deal.state.toLowerCase().includes('finalized')),
);

export const selectRequestedProposals = createSelector(
  state => selectAllProposals(state),
  deals => deals.filter(deal => deal.state.toLowerCase().includes('requested')),
);

export const selectCurrentProposal = createSelector(
  selectCurrentProposalId,
  selectAllProposals,
  (currentId: number, proposals: ProposalParams[]) =>
    proposals.find(({ id }: { id: number }) => id === currentId),
);

export const selectProposalAsOptions = createSelector(
  selectAllProposals,
  (allDeals: ProposalParams[]) =>
    allDeals.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export function selectProposal(
  state: RootState,
  proposalId: number,
): ProposalParams | undefined {
  return selectors.selectById(state, proposalId) || undefined;
}
