import { mode } from '@chakra-ui/theme-tools';
export default {
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        borderRadius: 'sm',
        h: 8,
        px: 3,
      },
      stepper: {
        fontSize: 'calc(1rem * 0.5)',
      },
    },
  },
  variants: {
    outline: props => ({
      field: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        borderColor: 'graphite.400',
        borderWidth: '1px',
        _hover: {
          borderColor: 'graphite.500',
        },
        _focus: {
          borderColor: 'graphite.500',
          boxShadow: 'unset',
        },
      },
      stepper: {
        borderColor: 'graphite.400',
      },
    }),
  },
};
