import * as React from 'react';
import { Box, Flex, Image, Icon, Text } from '@chakra-ui/react';
import { ControlledInputArray } from 'components/ControlledFormFields';
import { InputTextWithAutocompletion } from 'components/InputTextWithAutocompletion';
import { useAppSelector } from 'hooks';
import { FaApple } from 'react-icons/fa';

const LineitemTargetingAppPlacementTargetingPlacementList = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const apps = useAppSelector(state => state.consts.apps);
  const suggestions = React.useMemo(() => {
    if (!apps) return [];
    return apps.map((app: any) => ({
      value: app.id,
      display: (
        <Flex alignItems='center'>
          <Box
            width='2em'
            height='2em'
            background='graphite.400'
            borderRadius='base'
            overflow='hidden'
            mr={2}
          >
            {app.os === 'android' && (
              <Image
                width='100%'
                height='100%'
                src={`https://panel.mobigate.pl/assets/apps/${app.id}.png`}
              />
            )}
            {app.os === 'ios' && (
              <Icon width='100%' height='100%' as={FaApple} />
            )}
          </Box>

          <Box>
            <Text>{app.name}</Text>
            <Text fontSize='xs'>{app.id}</Text>
          </Box>
        </Flex>
      ),
      search: `${app.id} ${app.name}`,
    }));
  }, [apps]);

  return (
    <ControlledInputArray
      width='60ch'
      placeholder='com.example.app123 or 123456789'
      component={InputTextWithAutocompletion}
      name='targeting.appPlacementTargeting.placementList'
      label='Package names'
      suggestions={suggestions}
      isExpandable={true}
      {...props}
    />
  );
};

LineitemTargetingAppPlacementTargetingPlacementList.displayName =
  'Lineitem.targeting.appPlacementTargeting.placementList';

export { LineitemTargetingAppPlacementTargetingPlacementList };
