/* eslint-disable no-unreachable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { withErrorHandling } from 'store/wrappers';
import { selectCurrentDeal } from 'store/deals';
import { fetchHandler } from '../statistics.helpers';
// eslint-disable-next-line no-unused-vars
import adserver from 'services/adserver';
import {
  NAME,
  ALL_ENTITIES,
  ENTITY_DEAL,
  ENTITY_PACKAGENAME,
  ENTITY_WEBSITE,
  ENTITY_OS,
  ENTITY_BIDFLOOR,
  STAT_IMPRESSIONS,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  ENTITY_CITY,
  ENTITY_COORDINATE,
  ENTITY_LAT,
  ENTITY_LONG,
  API_COORDINATE_INDICATORS,
  ENTITY_EXCHANGE,
  ENTITY_CREATIVE,
} from '../statistics.consts';

// wspólny handler do pobierania statystyk dla bieżącego Deala
// zawęża zakres czasowy pobieranych statystyk do czasu emisji
const currentDealStatsHandler = async (query, thunkAPI) => {
  const state = thunkAPI.getState();
  const Deal = selectCurrentDeal(state);
  return await fetchHandler({
    entityType: ENTITY_DEAL,
    start: Deal.deal.start_date,
    end: Deal.deal.end_date,
    ...query,
    entities: {
      [ENTITY_DEAL]: Deal.id,
      ...query.entities,
    },
  });
};

// wspólny handler do pobierania statystyk dla wydawców i bieżącego Deala
const publisherInCurrentDealHandler = async (
  entityType,
  params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
  thunkAPI,
) => {
  return await currentDealStatsHandler(
    {
      entityType,
      params: params,
      entities: {
        [entityType]: ALL_ENTITIES,
      },
    },
    thunkAPI,
  );
};

export const fetchTotalStatsForCurrentDeal = createAsyncThunk(
  NAME + '/TotalForCurrentDeal',
  withErrorHandling(async (_, thunkAPI) =>
    currentDealStatsHandler({}, thunkAPI),
  ),
);

export const fetchMonthlyStatsForCurrentDeal = createAsyncThunk(
  NAME + '/MonthlyForCurrentDeal',
  withErrorHandling(async (_, thunkAPI) =>
    currentDealStatsHandler(
      {
        timeunit: TIMEUNIT_MONTH,
      },
      thunkAPI,
    ),
  ),
);

export const fetchWeeklyStatsForCurrentDeal = createAsyncThunk(
  NAME + '/WeeklyForCurrentDeal',
  withErrorHandling(async (_, thunkAPI) =>
    currentDealStatsHandler(
      {
        timeunit: TIMEUNIT_WEEK,
      },
      thunkAPI,
    ),
  ),
);

export const fetchDailyStatsForCurrentDeal = createAsyncThunk(
  NAME + '/DailyForCurrentDeal',
  withErrorHandling(async (_, thunkAPI) =>
    currentDealStatsHandler(
      {
        timeunit: TIMEUNIT_DAY,
      },
      thunkAPI,
    ),
  ),
);

export const fetchHourlyStatsForCurrentDeal = createAsyncThunk(
  NAME + '/HourlyForCurrentDeal',
  withErrorHandling(async (_, thunkAPI) =>
    currentDealStatsHandler(
      {
        timeunit: TIMEUNIT_HOUR,
      },
      thunkAPI,
    ),
  ),
);

export const fetchTotalStatsForAppsInCurrentDeal = createAsyncThunk(
  NAME + '/TotalStatsForAppsInCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_PACKAGENAME, params, thunkAPI),
  ),
);

export const fetchTotalStatsForWebsitesInCurrentDeal = createAsyncThunk(
  NAME + '/TotalStatsForWebsitesInCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_WEBSITE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForOperatingSystemsOfCurrentDeal = createAsyncThunk(
  NAME + '/TotalStatsForOperatingSystemsOfCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_OS, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCitiesOfCurrentDeal = createAsyncThunk(
  NAME + '/fetchTotalStatsForCitiesOfCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_CITY, params, thunkAPI),
  ),
);

export const fetchTotalStatsForExchangesOfCurrentDeal = createAsyncThunk(
  NAME + '/fetchTotalStatsForExchangesOfCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_EXCHANGE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCreativesOfCurrentDeal = createAsyncThunk(
  NAME + '/fetchTotalStatsForCreativesOfCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentDealHandler(ENTITY_CREATIVE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCoordinatesOfCurrentDeal = createAsyncThunk(
  NAME + '/fetchTotalStatsForCoordinatesOfCurrentDeal',
  withErrorHandling(async (params, thunkAPI) =>
    currentDealStatsHandler(
      {
        entities: {
          [ENTITY_LAT]: ALL_ENTITIES,
          [ENTITY_LONG]: ALL_ENTITIES,
        },
        entityType: ENTITY_COORDINATE,
        indicators: API_COORDINATE_INDICATORS,
      },
      thunkAPI,
    ),
  ),
);

export const fetchBidfloorsForDeal = createAsyncThunk(
  NAME + '/BidfloorsForDeal',
  withErrorHandling(async DealId => {
    if (!DealId) throw new Error('brak DealId');
    const resp = await adserver({
      url: '/statistics',
      params: {
        unit: 'bidfloor',
        DealId: DealId,
      },
    });

    const result = resp?.data?.result;

    return [
      {
        ...result,
        entityType: ENTITY_BIDFLOOR,
        id: `${ENTITY_BIDFLOOR}:${ENTITY_DEAL}:${DealId}`,
        DealId: DealId,
      },
    ];
  }),
);
