import React from 'react';
import { ControlledRadioGroup } from 'components';

const VALUES = [
  { name: 'Target selected', id: false },
  { name: 'Exclude selected', id: true },
];

const LineitemTargetingCityExclude = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledRadioGroup
    name='targeting.city.exclude'
    values={VALUES}
    valueType='bool'
    {...props}
  />
);

LineitemTargetingCityExclude.displayName = 'Lineitem.targeting.exclude';

export { LineitemTargetingCityExclude };
