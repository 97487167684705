import * as React from 'react';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const VALUES = [
  { name: 'Target selected', id: false },
  { name: 'Exclude selected', id: true },
];

const LineitemTargetingPlacementCategoryTargetingExclude = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledRadioGroup
    name='targeting.placementCategoryTargeting.exclude'
    values={VALUES}
    {...props}
  />
);

LineitemTargetingPlacementCategoryTargetingExclude.displayName =
  'Lineitem.targeting.placementCategoryTargeting.exclude';

export { LineitemTargetingPlacementCategoryTargetingExclude };
