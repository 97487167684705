import { configureStore, Store } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { persistStore } from 'redux-persist';

import adserver from 'services/adserver';
import rootReducer from './rootReducer';

const logger = createLogger({
  collapsed: true,
});

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware: [thunk.withExtraArgument(adserver), promise, logger],
});

export default store;
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
