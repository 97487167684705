import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { setCurrent as setCurrentCampaign } from 'store/campaigns';
import {
  setCurrent as setCurrentCreative,
  fetchAllCreativesForCurrentCampaign,
} from 'store/creatives';
import { setCurrent as setCurrentLineitem } from 'store/lineitems';
import { setCurrentOptimization } from 'store/optimizations';
import { setCurrentTargeting } from 'store/targetings';
import { setCurrentAdvertiser } from 'store/advertisers';
import { setCurrentDeal } from 'store/deals';
import { setCurrentProposal } from 'store/proposals';

type UrlParamType = {
  campaignId: number | null;
  creativeId: number | null;
  lineitemId: number | null;
  optimizationId: number | null;
  targetingId: number | null;
  advertiserId: number | null;
  dealId: number | null;
  proposalId: number | null;
};

export const UrlParamsHandler = (): null => {
  const params = useParams<{
    campaignId?: string;
    creativeId?: string;
    lineitemId?: string;
    optimizationId?: string;
    targetingId?: string;
    advertiserId?: string;
    dealId?: string;
    proposalId?: string;
  }>();
  const dispatch = useAppDispatch();
  const [parsedParams, setParsedParams] = React.useState<UrlParamType>({
    campaignId: NO_CURRENT_ENTITY,
    creativeId: NO_CURRENT_ENTITY,
    lineitemId: NO_CURRENT_ENTITY,
    optimizationId: NO_CURRENT_ENTITY,
    targetingId: NO_CURRENT_ENTITY,
    advertiserId: NO_CURRENT_ENTITY,
    dealId: NO_CURRENT_ENTITY,
    proposalId: NO_CURRENT_ENTITY,
  });

  React.useEffect(() => {
    const {
      campaignId,
      creativeId,
      lineitemId,
      optimizationId,
      targetingId,
      advertiserId,
      dealId,
      proposalId,
    } = params;

    setParsedParams({
      campaignId: campaignId ? Number.parseInt(campaignId) : NO_CURRENT_ENTITY,
      creativeId: creativeId ? Number.parseInt(creativeId) : NO_CURRENT_ENTITY,
      lineitemId: lineitemId ? Number.parseInt(lineitemId) : NO_CURRENT_ENTITY,
      optimizationId: optimizationId
        ? Number.parseInt(optimizationId)
        : NO_CURRENT_ENTITY,
      targetingId: targetingId
        ? Number.parseInt(targetingId)
        : NO_CURRENT_ENTITY,
      advertiserId: advertiserId
        ? Number.parseInt(advertiserId)
        : NO_CURRENT_ENTITY,
      dealId: dealId ? Number.parseInt(dealId) : NO_CURRENT_ENTITY,
      proposalId: proposalId ? Number.parseInt(proposalId) : NO_CURRENT_ENTITY,
    });
  }, [params]);

  React.useEffect(() => {
    dispatch(setCurrentCampaign(parsedParams.campaignId));
    if (parsedParams.campaignId)
      dispatch(fetchAllCreativesForCurrentCampaign({}));
  }, [parsedParams?.campaignId]);

  React.useEffect(() => {
    dispatch(setCurrentLineitem(parsedParams.lineitemId));
  }, [parsedParams?.lineitemId]);

  React.useEffect(() => {
    dispatch(setCurrentCreative(parsedParams.creativeId));
  }, [parsedParams?.creativeId]);

  React.useEffect(() => {
    dispatch(setCurrentOptimization(parsedParams.optimizationId));
  }, [parsedParams?.optimizationId]);

  React.useEffect(() => {
    dispatch(setCurrentTargeting(parsedParams.targetingId));
  }, [parsedParams?.targetingId]);

  React.useEffect(() => {
    dispatch(setCurrentAdvertiser(parsedParams.advertiserId));
  }, [parsedParams?.advertiserId]);

  React.useEffect(() => {
    dispatch(setCurrentDeal(parsedParams.dealId));
  }, [parsedParams?.dealId]);

  React.useEffect(() => {
    dispatch(setCurrentProposal(parsedParams.proposalId));
  }, [parsedParams?.proposalId]);
  return null;
};
