import React from 'react';
import { ControlledInputText } from 'components';

export const CampaignName = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputText name='name' label='Campaign Name' {...props} />
);
