import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import { NAME } from './modelsets.consts';
import adapter, { initialState } from './modelsets.adapter';
import {
  fetchAllModelsets,
  fetchById,
  create,
  update,
  remove,
  select,
} from './modelsets.actions';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllModelsets.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(fetchById.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(create.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(remove.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(select.fulfilled, (state, action) => {
        state.selected = action.payload;
      })
      .addCase(NAME + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
