import * as React from 'react';
import { Box, Heading, Center, Stack } from '@chakra-ui/react';
import { CampaignsListLink } from 'components/Campaign';

export const ErrorPage = ({
  status = 'Error',
  message = 'Please try again later',
}: {
  status?: string | number;
  message?: string;
}): JSX.Element => (
  <Center width='100%' paddingY={12}>
    <Stack as={Box}>
      <Heading fontSize='8xl' lineHeight={1}>
        {status}
      </Heading>
      <Heading fontSize='4xl'>{message}</Heading>
      <br />
      <CampaignsListLink width='fit-content' variant='primary' size='sm'>
        Open Dashboard
      </CampaignsListLink>
    </Stack>
  </Center>
);
