import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { isBefore, startOfToday } from 'date-fns';
import { ControlledInputDate } from 'components';

const STARTDATE = 'startDate';

export const CampaignStartDate = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  // https://spicymobile.atlassian.net/jira/software/c/projects/PP/boards/3?modal=detail&selectedIssue=PP-893
  // > A to nie jest po prostu zła interpretacja komunikatu? Bo to nie jest tak, że nie można daty startu ustawić na przeszłą, tylko nie można w ogóle zmienić daty startu, jak ta jest w przeszłości.
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: startDateValue },
  } = useController({
    name: STARTDATE,
    control,
  });
  const {
    field: { value: endDateValue },
  } = useController({
    name: 'endDate',
    control,
  });

  React.useEffect(() => {
    const startOfCampaignIsInThePast = isBefore(startDateValue, startOfToday());
    setIsDisabled(startOfCampaignIsInThePast);
  }, [startDateValue, startOfToday, isBefore]);

  return (
    <ControlledInputDate
      label='Start Date'
      name={STARTDATE}
      type='date'
      min={startOfToday()}
      max={endDateValue}
      isDisabled={isDisabled}
      withInputTime
      {...props}
    />
  );
};
