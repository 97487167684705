import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import {
  selectAdvertiserAsOptionsOfUser,
  selectAdvertiserAsOptionsOfCampaign,
} from 'store/advertisers';
import { useFormContext } from 'react-hook-form';

const CampaignAdvertiser = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const { getValues } = useFormContext();
  const campaignId = getValues('id');

  const advertisersOfUser = useAppSelector(state =>
    selectAdvertiserAsOptionsOfUser(state),
  );

  const advertisersOfCampaign = useAppSelector(state =>
    selectAdvertiserAsOptionsOfCampaign(state, campaignId),
  );

  const advertisers = campaignId ? advertisersOfCampaign : advertisersOfUser;

  const options = React.useMemo(() => {
    return advertisers?.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }));
  }, [advertisers]);

  const placeholder = options?.length
    ? 'Select an advertiser'
    : 'No advertisers found';

  return (
    <ControlledSelect
      name='advertiser'
      label='Advertiser'
      placeholder={placeholder}
      options={options}
      isCleareable={true}
      isDisabled={!options?.length}
      {...props}
    />
  );
};

CampaignAdvertiser.displayName = 'Campaign.advertiser';

export { CampaignAdvertiser };
