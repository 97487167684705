import { createEntityAdapter } from '@reduxjs/toolkit';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: DATA_UNDEFINED,
  errors: NO_ERRORS,
});

export { initialState };
export default adapter;
