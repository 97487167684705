import React from 'react';
import { Flex, Checkbox } from '@chakra-ui/react';
import { useAppSelector } from 'hooks';
import { selectBrandsEntities } from 'store/devicebrands';
import { selectDevicesEntities } from 'store/devices';
import useIsSelected from '../useIsSelected';
import { BRANDS_FIELD_NAME, DEVICES_FIELD_NAME } from '../consts';
import { useFormContext, useController } from 'react-hook-form';

interface SelectedEntityProps {
  entityId: number;
  entityName: string | React.ReactNode;
  fieldName: string;
  isDisabled?: boolean;
}

const SelectedEntity = ({
  fieldName,
  entityId,
  entityName,
  isDisabled,
}: SelectedEntityProps): JSX.Element => {
  const { isSelected, handleSelectionChange } = useIsSelected(
    fieldName,
    entityId,
  );

  return (
    <Checkbox
      size='sm'
      isChecked={isSelected}
      isDisabled={isDisabled}
      onChange={handleSelectionChange}
    >
      {entityName}
    </Checkbox>
  );
};

interface SelectedEntitiesProps {
  isDisabled?: boolean;
  [key: string]: any;
}

const SelectedEntities = ({
  isDisabled,
  ...props
}: SelectedEntitiesProps): JSX.Element | null => {
  const { control } = useFormContext();

  const brandsEntities = useAppSelector(selectBrandsEntities);
  const devicesEntities = useAppSelector(selectDevicesEntities);

  const {
    field: { value: selectedBrandsIds = [] },
  } = useController({
    name: BRANDS_FIELD_NAME,
    control,
  });

  const {
    field: { value: selectedDevicesIds = [] },
  } = useController({
    name: DEVICES_FIELD_NAME,
    control,
  });

  const brands =
    brandsEntities &&
    selectedBrandsIds.map((brandId: any) => (
      <SelectedEntity
        key={brandId}
        entityId={brandId}
        entityName={brandsEntities[brandId]?.name || 'Loading...'}
        fieldName={BRANDS_FIELD_NAME}
        isDisabled={isDisabled}
      />
    ));

  const devices =
    devicesEntities &&
    selectedDevicesIds.map((deviceId: any) => {
      const { name: deviceName, brand: { name: brandName = '' } = {} } =
        devicesEntities[deviceId] || {};

      return (
        <SelectedEntity
          key={deviceId}
          entityId={deviceId}
          entityName={
            brandName && deviceName
              ? `${brandName} ${deviceName}`
              : 'Loading...'
          }
          fieldName={DEVICES_FIELD_NAME}
          isDisabled={isDisabled}
        />
      );
    });

  if (brands.length || devices.length) {
    return (
      <Flex direction='row' wrap='wrap' gridGap={2} {...props}>
        {brands}
        {devices}
      </Flex>
    );
  }

  return null;
};

export default SelectedEntities;
