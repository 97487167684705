import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { selectAllBrands, selectHighlightedBrands } from 'store/devicebrands';
import { Brands, BrandsWithFilteringInput } from './Brands';

const Container = () => {
  const allBrands = useSelector(selectAllBrands);
  const highlightedBrands = useSelector(selectHighlightedBrands);
  return (
    <Tabs variant='solid-rounded' colorScheme='gray' size='sm'>
      <TabList>
        <Tab>Popular brands</Tab>
        <Tab>All brands</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <Brands data={highlightedBrands} />
        </TabPanel>
        <TabPanel px={0}>
          <BrandsWithFilteringInput
            array={allBrands}
            filteredKey='name'
            minStringLengthRequired={2}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Container;
