import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseDateFromApi } from 'helpers/datetimeHelpers';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { selectById } from './../deals/deals.selectors';
import { REDUCER_KEY } from './proposals.consts';
import { selectCurrentProposalId } from './proposals.selectors';
import { ProposalParams } from './proposals.types';

function normalizeApiReponse(data: ProposalParams) {
  const { deals } = data;
  const finalDeals = deals.map(deal => {
    const start_date = parseDateFromApi(deal.start_date) || null;
    const end_date = parseDateFromApi(deal.end_date) || null;
    return {
      ...deal,
      start_date,
      end_date,
    };
  });

  return {
    ...data,
    deals: finalDeals,
  };
}

const fetchByIdHandler = async ({ proposalId }: { proposalId: number }) => {
  const proposal = await adserver({
    url: `/proposal/${proposalId}`,
    headers: {},
  });

  return normalizeApiReponse(proposal.data);
};

export const fetchAllProposals = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/proposals', headers: {} });
    return response.data;
  }),
);

export const fetchCurrentProposal = createAsyncThunk(
  REDUCER_KEY + '/fetchCurrentProposal',
  // eslint-disable-next-line no-unused-vars
  withErrorHandling(async (_: undefined, thunkAPI: any) => {
    const proposalId = selectCurrentProposalId(thunkAPI.getState());
    if (!proposalId)
      return thunkAPI.rejectWithValue('currentProposalId is unavailable');

    return await fetchByIdHandler({ proposalId });
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ proposalId }: { proposalId: number }) => {
    const response = await adserver({
      url: `/proposal/${proposalId}`,
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const setCurrentProposal = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (proposalId: number) => {
    if (!proposalId) return;
    const proposal = await fetchByIdHandler({ proposalId });
    return proposal;
  }),
);

export const acceptProposal = createAsyncThunk(
  REDUCER_KEY + '/accept',
  withErrorHandling(
    async (
      { id, revision }: { id: number; revision: string },
      thunkAPI: any,
    ) => {
      const proposal = selectById(thunkAPI.getState(), id);
      await adserver({
        url: `/accept-proposal/${id}`,
        method: 'POST',
        data: {
          revision,
        },
        headers: {},
      });
      return { ...proposal, status: 'Finalized' };
    },
  ),
);

export const removeAllProposals = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
