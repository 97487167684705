import { createSelector } from 'reselect';
import adapter from './devices.adapter';
import { NAME } from './devices.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const {
  selectIds: selectDevicesIds,
  selectEntities: selectDevicesEntities,
  selectTotal: selectTotalNumberOfDevices,
  selectById: selectDeviceById,
} = selectors;

export const selectAllDevices = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);
