import React from 'react';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import SelectedEntities from './SelectedEntities';
import Browser from '../Browser/Container';
import { CAN_VIEW_CAMPAIGNS, CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import { PermissionsGate } from 'components/PermissionsGate';

const Field = () => {
  const userCanEditField = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);

  return (
    <PermissionsGate requiredPermissions={[CAN_VIEW_CAMPAIGNS]}>
      <SelectedEntities isDisabled={!userCanEditField} mb={4} />
      {userCanEditField && <Browser />}
    </PermissionsGate>
  );
};

export default Field;
