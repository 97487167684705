import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseDateFromApi } from 'helpers/datetimeHelpers';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './deals.consts';
import { DealParams } from './deals.types';

function normalizeApiReponse(data: DealParams) {
  const start_date = parseDateFromApi(data.deal?.start_date) || null;
  const end_date = parseDateFromApi(data.deal?.end_date) || null;
  return {
    ...data,
    deal: {
      ...data.deal,
      start_date,
      end_date,
    },
  };
}

const fetchByIdHandler = async ({ dealId }: { dealId: number }) => {
  const deal = await adserver({
    url: `/finalized-deal/${dealId}`,
    headers: {},
  });

  return normalizeApiReponse(deal.data);
};

export const fetchAllDeals = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/finalized-deals', headers: {} });
    return response.data.map((deal: DealParams) => normalizeApiReponse(deal));
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ dealId }: { dealId: number }) => {
    const response = await adserver({
      url: `/finalized-deal/${dealId}`,
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const setCurrentDeal = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (dealId: number) => {
    if (!dealId) return;
    const deal = await fetchByIdHandler({ dealId });
    return deal;
  }),
);

export const getPrivateDealsReport = createAsyncThunk(
  REDUCER_KEY + '/getPrivateDealsReport',
  withErrorHandling(async () => {
    const { data } = await adserver({
      url: '/deals/report',
      method: 'GET',
      responseType: 'blob',
      headers: {},
    });
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'deal_report.xlsx');
    document.body.appendChild(link);
    link.click();
  }),
);

export const removeAllDeals = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
