import React from 'react';
import { ControlledInputNumber } from 'components';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { CAN_EDIT_MARGIN } from 'consts/permissions';

const CampaignMargin = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {

  const hasEditPermissions = userHasRequiredPermissions([CAN_EDIT_MARGIN]);

  return (
    <ControlledInputNumber
      name='margin.percentValue'
      label='Margin (%)'
      helper='Margin added to campaign spend'
      isDisabled={!hasEditPermissions}
      min={0}
      precision={0}
      step={1}
      {...props}
    />
  );
};

CampaignMargin.displayName = 'Campaign.margin';

export { CampaignMargin };
