import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';
import components from './components';
import colors from './colors';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  fontWeights: {
    bold: 600,
  },
  radii: {
    xs: '0.125rem',
    sm: '0.25rem',
  },
  colors: {
    ...colors,
    salmon: {
      100: '#faa002',
      200: '#e59202',
      300: '#d08400',
    },
    grass: {
      100: '#39b54a',
      200: '#16451c',
      300: '#0a1f0d',
    },
    graphite: {
      100: 'rgba(51,51,51,0.05)',
      200: 'rgba(51,51,51,0.1)',
      300: 'rgba(51,51,51,0.25)',
      400: 'rgba(51,51,51,0.5)',
      500: '#333333',
      600: '#4d4d4d',
      700: '#666666',
    },
    // gray: {
    //   100: 'rgba(51,51,51,0.05)',
    //   200: 'rgba(51,51,51,0.1)',
    //   300: 'rgba(51,51,51,0.25)',
    //   400: 'rgba(51,51,51,0.5)',
    //   500: '#333333',
    //   600: '#333333',
    //   700: '#333333',
    // },
    white: {
      100: '#fff',
      200: '#f8f8f8',
    },
    dark: {
      100: '#212322',
      200: '#0c0f0c',
      300: '#e3e7f0',
    },
    selected: 'rgba(18, 22, 77, 0.1)',
    primary: '#faa002',
    secondary: '#fff',
    navyBlue: {
      200: '#414470',
      300: '#292D5E',
      400: '#12164d',
      500: '#101345',
      600: '#0E113D',
      800: '#0A0D2E',
    },
    avatar: {
      bg: '#fedbeb',
      text: '#8f4567',
    },
    status: {
      active: {
        bg: '#e4fdd6',
        text: '#75a761',
      },
      inactive: {
        bg: '#fedbdb',
        text: '#d56868',
      },
    },
  },
  styles: {
    global: props => ({
      'html, body': {
        bg: mode('white.200', 'dark.200')(props),
        color: mode('graphite.500', 'dark.300')(props),
      },
      '*': {
        scrollbarWidth: 'auto',
        scrollbarColor: 'transparent',
      },

      '*::-webkit-scrollbar': {
        width: '12px',
      },

      '*::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '*::-webkit-scrollbar-corner': { background: 'rgba(0, 0, 0, 0)' },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: mode('graphite.400', 'graphite.600')(props),
        borderRadius: '10px',
        borderWidth: '3px',
        borderColor: mode('white.200', 'dark.200')(props),
        borderStyle: 'solid',
      },
      'input::-webkit-datetime-edit': {
        cursor: 'text',
      },
      'input::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
        position: 'absolute',
        right: '.25em',
        opacity: 0.6,
        filter: mode('invert(0)', 'invert(1)')(props),
      },
      '.recharts-surface': {
        overflow: 'visible',
      },
      '.recharts-cartesian-grid line': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-cartesian-axis line': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-cartesian-axis text': {
        fill: mode('graphite.400', 'graphite.600')(props),
        fontSize: 'xs',
        fontWeight: 'bold',
      },
      '.recharts-legend-item-text': {
        fontSize: 'md',
        fontWeight: 'bold',
        color: mode('graphite.400', 'graphite.600')(props),
      },
      '.recharts-tooltip-cursor': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-line path': {
        strokeWidth: 3,
      },
      '.recharts-line-dots circle': {
        strokeWidth: 3,
        fill: mode('graphite.50', 'graphite.800')(props),
      },
    }),
  },
  shadows: {
    base: '0px 5px 20px 0px rgba(49, 49, 77, 0.05)',
  },
  components,
});

export default theme;
