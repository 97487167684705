import { createSelector } from 'reselect';
import { RootState } from 'store';
import { NAME } from './consts.consts';
import { CreativeTemplateType } from './consts.types';

export type OptionsArray = Array<{
  id: number | string;
  name: string;
}>;

const selectConstsState = (state: RootState) => state[NAME];

// pola kampanii
export const selectIabCategories = (state: RootState): OptionsArray =>
  state[NAME].iabCategories;
export const selectRestrictedCategories = (state: RootState): OptionsArray =>
  state[NAME].restrictedCategories;

// pola kreacji
export const selectAdType = (state: RootState): OptionsArray =>
  state[NAME].adTypes;
export const selectCreativeFormats = (state: RootState): OptionsArray =>
  state[NAME].creativeFormats;
export const selectCreativeAttributes = (state: RootState): OptionsArray =>
  state[NAME].creativeAttributes;
export const selectGoogleAttributes = (state: RootState): OptionsArray =>
  state[NAME].googleAttributes;
export const selectCreativeTemplateName = (state: RootState): OptionsArray =>
  state[NAME].templates;

// pola linetema
export const selectApps = (state: RootState): OptionsArray => state[NAME].apps;
export const selectBiddingModels = (state: RootState): OptionsArray =>
  state[NAME].biddingModels;
export const selectCities = (state: RootState): OptionsArray =>
  state[NAME].cities;
export const selectCountries = (state: RootState): OptionsArray =>
  state[NAME].countries;
export const selectDomains = (state: RootState): OptionsArray =>
  state[NAME].domains;
export const selectEventTypes = (state: RootState): OptionsArray =>
  state[NAME].eventTypes;
export const selectExchanges = (state: RootState): OptionsArray =>
  state[NAME].exchanges;
export const selectFrequencyCappingPeriods = (state: RootState): OptionsArray =>
  state[NAME].frequencyCappingPeriod;
export const selectGeoTargetingPrecisions = (state: RootState): OptionsArray =>
  state[NAME].geoTargetingPrecision;
export const selectLanguages = (state: RootState): OptionsArray =>
  state[NAME].languages;
export const selectMimeTypes = (state: RootState): OptionsArray =>
  state[NAME].mimeTypes;
export const selectOperatingSystems = (state: RootState): OptionsArray =>
  state[NAME].operatingSystems;
export const selectProvinces = (state: RootState): OptionsArray =>
  state[NAME].provinces;
export const selectTargetingLanguages = (state: RootState): OptionsArray =>
  state[NAME].targetingLanguages;
export const selectTargetingListTypes = (state: RootState): OptionsArray =>
  state[NAME].targetingListTypes;
export const selectTrafficSources = (state: RootState): OptionsArray =>
  state[NAME].trafficSource;
export const selectUserCategories = (state: RootState): OptionsArray =>
  state[NAME].userCategories;
export const selectWeekdays = (state: RootState): OptionsArray =>
  state[NAME].days;
export const selectRetargetingTypes = (state: RootState): OptionsArray =>
  state[NAME].retargetingTypes;
export const selectAgeDemographics = (state: RootState): OptionsArray =>
  state[NAME].ageDemographics;
export const selectGenderDemographics = (state: RootState): OptionsArray =>
  state[NAME].genderDemographics;
export const selectPlacementCategories = (state: RootState): OptionsArray =>
  state[NAME].placementCategories;
export const selectVideoCreativeFormats = (state: RootState): OptionsArray =>
  state[NAME].videoCreativeFormats;
export const selectVideoPlacementTypes = (state: RootState): OptionsArray =>
  state[NAME].videoPlacementTypes;
export const selectVideoPlaybackMethodTypes = (
  state: RootState,
): OptionsArray => state[NAME].videoPlaybackMethodTypes;
export const selectVideoStartDelayTypes = (state: RootState): OptionsArray =>
  state[NAME].videoStartDelayTypes;

export const selectCreativeTemplates = createSelector(
  selectConstsState,
  consts => consts.templates,
);

export const selectCreativeTemplate = (
  state: RootState,
  creativeTemplateId: number,
): CreativeTemplateType | undefined => {
  const creativeTemplates = selectCreativeTemplates(state);

  return creativeTemplates?.find(
    (creativeTemplate: CreativeTemplateType) =>
      creativeTemplate.id === creativeTemplateId,
  );
};
