import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';

const LineitemTargetingLanguage = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const languages = useAppSelector(state => state.consts.targetingLanguages);

  const options = React.useMemo(() => {
    return languages?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [languages]);

  return (
    <ControlledSelectMultiple
      name='targeting.language'
      options={options}
      {...props}
    />
  );
};

LineitemTargetingLanguage.displayName = 'Lineitem.targeting.language';

export { LineitemTargetingLanguage };
