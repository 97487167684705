import React from 'react';
import { ControlledCheckboxGroup } from 'components';
import { useAppSelector } from 'hooks';
import { useFormContext, useController } from 'react-hook-form';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';

const LineitemTargetingExchange = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  /**
   * lista wszystkich exchanges
   */
  const exchanges = useAppSelector(state => state.consts.exchanges);
  /**
   * lista traffic sources; każdy traffic source zawiera listę id exchange, które obsługuje
   */
  const allTrafficSources = useAppSelector(state => state.consts.trafficSource);
  /**
   * zawartość pola trafficSource w formularzu: lista kluczy wybranych trafficSource,
   * na bazie której generujemy listę dozwolonych do wybrania exchange
   */
  const { control } = useFormContext();
  const {
    field: { value: selectedTrafficSources },
  } = useController({
    name: 'targeting.trafficSource',
    control,
  });
  /**
   * lista dozwolonych exchange jest generowana na podstawie bieżąco wybranych trafficSource
   * każdy trafficSource w consts zawiera listę przypisanych do niego kluczy id exchange
   * pobieramy listę bieżąco wybranych traffic sources
   * generujemy unikalny set z kluczami dozwolonych exchange
   * generujemy na bazie setu tablicę z opcjami
   *
   */
  const userCanEditField = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);

  const allowedExchanges = React.useMemo(() => {
    if (!allTrafficSources) return exchanges;
    /**
     * unikalna lista kluczy exchanges, wygenerowana na bazie bieżąco wybranych trafficSource
     */
    const allowedExchangesKeys = [
      ...new Set(
        selectedTrafficSources?.flatMap((trafficSourceId: any) => {
          const trafficSource = allTrafficSources.find(
            ({ id }: { id: string }) => id === trafficSourceId,
          );
          return trafficSource.exchanges;
        }),
      ),
    ];

    return exchanges?.map((system: any) => {
      const isAllowed = allowedExchangesKeys.includes(system.id);
      if (!userCanEditField) {
        return {
          ...system,
          isDisabled: true,
        };
      }

      return {
        ...system,
        isDisabled: !isAllowed,
      };
    });
  }, [exchanges, allTrafficSources, selectedTrafficSources, userCanEditField]);

  return (
    <ControlledCheckboxGroup
      name='targeting.exchange'
      label='Exchange'
      values={allowedExchanges}
      w='100%'
      {...props}
    />
  );
};

LineitemTargetingExchange.displayName = 'Lineitem.targeting.exchange';

export { LineitemTargetingExchange };
