export const TextStyles = {
  variants: {
    link: {
      color: 'salmon.300',
      fontSize: 'sm',
      fontWeight: 'medium',
      textDecoration: 'underline',
      _hover: {
        color: 'salmon.100',
      },
    },
  },
};
