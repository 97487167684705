import { ControlledSelectMultiple } from 'components';
import { useAppSelector } from 'hooks';
import * as React from 'react';
import { selectActiveDealsAsOptions } from 'store/deals';

const LineitemGeneralPrivateMarketplace = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const deals = useAppSelector(selectActiveDealsAsOptions);

  const options = React.useMemo(() => {
    return deals;
  }, [deals]);

  return (
    <ControlledSelectMultiple
      label='Deals'
      name='general.privateMarketplace'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

LineitemGeneralPrivateMarketplace.displayName =
  'Lineitem.general.privateMarketplace';

export { LineitemGeneralPrivateMarketplace };
