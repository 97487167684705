import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'hooks';
import {
  selectAllCampaigns,
  fetchAll as fetchAllCampaigns,
} from 'store/campaigns';
import { ControlledSelectMultiple } from 'components/ControlledFormFields';

const LineitemTargetingCampaignRetargetingCampaignIds = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const allCampaigns = useAppSelector(selectAllCampaigns);

  const options = React.useMemo(() => {
    return allCampaigns?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [allCampaigns]);

  React.useEffect(() => {
    if (allCampaigns.length > 1) return;
    dispatch(fetchAllCampaigns());
  }, []);

  return (
    <ControlledSelectMultiple
      name='targeting.campaignRetargeting.campaignIds'
      label='Campaigns'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

LineitemTargetingCampaignRetargetingCampaignIds.displayName =
  'Lineitem.targeting.campaignRetargeting.campaignIds';

export { LineitemTargetingCampaignRetargetingCampaignIds };
