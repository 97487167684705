import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { NAME } from './optimizations.consts';
import adapter, { initialState } from './optimizations.adapter';
import {
  fetchAllOptimizations,
  fetchById,
  update,
  setCurrentOptimization,
} from './optimizations.actions';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllOptimizations.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(fetchById.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(setCurrentOptimization.fulfilled, (state, action) => {
        if (action.payload) {
          state.current = action.payload;
        } else {
          state.current = NO_CURRENT_ENTITY;
        }
      })
      .addCase(NAME + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
