import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const LineitemTargetingCampaignRetargetingTargetingType = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const retargetingTypes = useAppSelector(
    state => state.consts.retargetingTypes,
  );

  return (
    <ControlledRadioGroup
      name='targeting.campaignRetargeting.targetingType'
      label='Event Type'
      values={retargetingTypes}
      {...props}
    />
  );
};

LineitemTargetingCampaignRetargetingTargetingType.displayName =
  'Lineitem.targeting.campaignRetargeting.targetingType';

export { LineitemTargetingCampaignRetargetingTargetingType };
