import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './targetings.consts';

export const fetchAllTargetings = createAsyncThunk(
  NAME + '/list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adserver({ url: '/targetings' });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchById = createAsyncThunk(
  NAME + '/get',
  async ({ targetingId }, { rejectWithValue }) => {
    try {
      const response = await adserver({ url: `/targeting/${targetingId}` });
      delete response.data.gsPath;
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * upload plików, wykorzystywany przez createTargeting oraz updateTargeting
 */
const handleFileUpload = async ({ url, method, data, dispatch }) => {
  // wymagane do wysłania pliku jako multipart/form-data
  const formData = new FormData();
  for (const key in data) {
    if (data) formData.append(key, data[key]);
  }

  const response = await adserver({
    url,
    method,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: ({ loaded, total }) =>
      dispatch(
        setUploadProgress({ percentValue: Math.round((loaded * 100) / total) }),
      ),
  });

  return response.data;
};

export const create = createAsyncThunk(
  NAME + '/create',
  async (targetingValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await handleFileUpload({
        url: '/targeting/create',
        method: 'POST',
        data: targetingValues,
        dispatch,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const update = createAsyncThunk(
  NAME + '/update',
  async (targetingValues, { dispatch, rejectWithValue }) => {
    try {
      const { id: targetingId } = targetingValues;
      if (!targetingId) throw new Error('no id provided');
      const response = await handleFileUpload({
        url: `/targeting/${targetingId}`,
        method: 'PATCH',
        data: targetingValues,
        dispatch,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const remove = createAsyncThunk(
  NAME + '/delete',
  async ({ targetingId }, { rejectWithValue }) => {
    try {
      await adserver({ url: '/targeting/' + targetingId, method: 'DELETE' });
      return targetingId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const setUploadProgress = ({ percentValue }) => ({
  type: NAME + '/upload',
  payload: percentValue,
});

export const setCurrentTargeting = createAsyncThunk(
  NAME + '/current',
  withErrorHandling(async targetingId => {
    return targetingId;
  }),
);

export const removeAllTargetings = () => ({ type: NAME + '/removeAll' });
