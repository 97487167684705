import * as React from 'react';
import {
  Box,
  Button,
  Image,
  InputProps,
  UnorderedList,
  ListItem,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useFormControl,
  useMultiStyleConfig,
  useToast,
} from '@chakra-ui/react';
import {
  useDropzone,
  FileWithPath,
  Accept,
  FileRejection,
} from 'react-dropzone';
import { FiUploadCloud, FiEye } from 'react-icons/fi';
import adserver from 'services/adserver';
import { formatApiErrors } from 'helpers';

type FileDataType = {
  name: string;
  image?: string;
  timestamp: Date;
};

export type InputFileWithUploadType = {
  campaignId: number;
  fileId?: number;
  accept?: Accept;
  minSize?: number;
  maxSize?: number;
  isDisabled?: boolean;
  onChange: (fileId?: number) => void;
};

/**
 * Komponent obsługujący upload plików na potrzeby kreacji
 * Plik jest wysyłany do endpointa API, zwracane jest id, które to staje się wartością pola formularza
 */
export const InputFileWithUpload = ({
  campaignId,
  fileId,
  isDisabled,
  accept,
  onChange,
  size,
  variant,
  minSize,
  maxSize,
  ...props
}: InputFileWithUploadType & InputProps): JSX.Element => {
  const toast = useToast({
    position: 'bottom',
    status: 'error',
    isClosable: true,
  });
  const chakraInputProps = useFormControl(props);
  const chakraInputStyle = useMultiStyleConfig('InputFile', {
    size,
    variant,
  });
  const [fileData, setFileData] = React.useState<FileDataType | undefined>();

  /**
   * status uploadowania pliku: undefined dla idle lub cyfra 0-100 oznaczająca procent uploadu
   */
  const [uploadingProgress, setUploadingProgesss] = React.useState<
    number | undefined
  >();

  const handleAcceptedFiles = (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length) {
      uploadFile(acceptedFiles[0]);
    }
  };

  const handleRejectedFiles = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(fileRejection => {
      toast({
        title: 'File rejected',
        description: (
          <UnorderedList>
            {fileRejection.errors.map(error => (
              <ListItem key={error.code}>{error.message}</ListItem>
            ))}
          </UnorderedList>
        ),
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleAcceptedFiles,
    onDropRejected: handleRejectedFiles,
    maxFiles: 1,
    disabled: isDisabled,
    accept,
    minSize,
    maxSize,
  });

  const handleServerFileData = (data: {
    name: string;
    image: string;
    timestamp: string;
  }) =>
    setFileData({
      ...data,
      timestamp: new Date(data.timestamp),
    });

  async function getUploadedFileData(fileId: number) {
    const result = await adserver({
      url: `/campaign/${campaignId}/creative-image/${fileId}`,
      headers: {},
    });
    handleServerFileData(result.data);
  }

  async function uploadFile(file: FileWithPath) {
    try {
      setFileData({
        name: file.name,
        image: file.type.startsWith('image')
          ? URL.createObjectURL(file)
          : undefined,
        timestamp: new Date(),
      });

      const url = `/campaign/${campaignId}/creative-image`;
      const method = 'POST';
      const data = new FormData();
      data.append('image', file);
      data.append('name', file.name);

      const result = await adserver({
        url,
        method,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (status: { loaded: number; total: number }) =>
          setUploadingProgesss(
            Math.round((status.loaded * 100) / status.total),
          ),
      });

      handleServerFileData(result.data);

      if (!onChange) return;
      onChange(result.data?.id || undefined);
    } catch (error: any) {
      toast({
        title: 'Error during file upload',
        description: formatApiErrors(error),
      });

      setFileData(undefined);

      if (onChange) onChange(undefined);
    }
    setUploadingProgesss(undefined);
  }

  React.useEffect(() => {
    if (fileId) {
      getUploadedFileData(fileId);
    } else {
      setFileData(undefined);
    }
  }, [fileId]);

  return (
    <Popover
      trigger='hover'
      isOpen={fileData ? undefined : false}
      isLazy
      placement='top'
    >
      <Box
        __css={chakraInputStyle.field}
        {...chakraInputProps}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <PopoverTrigger>
          <Box sx={chakraInputStyle.preview}>
            {fileData?.image && (
              <Image src={fileData.image} sx={chakraInputStyle.image} />
            )}
            <Box sx={chakraInputStyle.previewIcon}>
              <Icon as={fileData ? FiEye : FiUploadCloud} />
            </Box>
          </Box>
        </PopoverTrigger>

        <Box __css={chakraInputStyle.heading}>{fileData?.name}</Box>
        <Box __css={chakraInputStyle.details}>
          {!uploadingProgress && fileData && (
            <Box>
              uploaded on{' '}
              {fileData.timestamp.toLocaleString('pl-PL', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: undefined,
              })}
            </Box>
          )}
          {uploadingProgress && <Box>{uploadingProgress}%</Box>}
        </Box>
        <Button colorScheme='gray' size='sm' sx={chakraInputStyle.action}>
          Choose file
        </Button>
      </Box>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Image src={fileData?.image} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
