import * as React from 'react';
import { Box, Textarea, Button, useClipboard } from '@chakra-ui/react';
import { RootState } from 'store';
import { withCurrentCampaignId } from 'hoc';
import { useAppSelector } from 'hooks';
import { selectCampaign } from 'store/campaigns';
import { CampaignId } from './Campaign.types';
import { CampaignInterface } from './Campaign.schema';
import { Card } from 'layouts';

const ActionTrackingCode = ({ campaignId }: CampaignId): JSX.Element => {
  const campaignSelector = (state: RootState): CampaignInterface =>
    selectCampaign(state, campaignId);
  const campaign = useAppSelector(campaignSelector);
  const actionTrackingCode = campaign?.actionTracking?.pixel || '';
  const { hasCopied, onCopy } = useClipboard(actionTrackingCode);

  return (
    <Card size='md' variant=''>
      <Box position='relative'>
        <Textarea value={actionTrackingCode} isReadOnly height='40ch' />
        <Button
          onClick={onCopy}
          size='sm'
          position='absolute'
          top={2}
          right={2}
        >
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
      </Box>
    </Card>
  );
};

const ActionTrackingCodeOfCurrentCampaign = withCurrentCampaignId(
  ActionTrackingCode,
);

export { ActionTrackingCode, ActionTrackingCodeOfCurrentCampaign };
