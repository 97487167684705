import React from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath, useParams } from 'react-router-dom';
import { BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { selectById as selectOptimizationById } from 'store/optimizations';

import { OPTIMIZATION_LIST, OPTIMIZATION_SHOW } from 'routes';

export const Optimizations = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(OPTIMIZATION_LIST)}>
      Optimizations
    </BreadcrumbLink>
  </BreadcrumbItem>
);

export const Optimization = ({ ...props }) => {
  const urlParams = useParams();
  const optimizationId = Number.parseInt(urlParams.optimizationId);
  const optimizationObj = useSelector(state =>
    selectOptimizationById(state, optimizationId),
  );

  return (
    <BreadcrumbItem {...props}>
      <BreadcrumbLink
        as={Link}
        to={generatePath(OPTIMIZATION_SHOW, { optimizationId })}
      >
        {optimizationObj?.optimizationName || 'Optimization'}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};
