import { createSelector } from 'reselect';
import adapter from './devicebrands.adapter';
import { NAME } from './devicebrands.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const {
  selectIds: selectBrandsIds,
  selectEntities: selectBrandsEntities,
  selectTotal: selectTotalNumberOfBrands,
  selectById: selectBrandById,
} = selectors;

export const selectAllBrands = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);

export const selectHighlightedBrands = createSelector(
  selectAllBrands,
  state => state[NAME].highlighted,
  (all, highlighted) => all.filter(brand => highlighted.includes(brand.id)),
);
