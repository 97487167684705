import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components/ControlledFormFields';

const LineitemTargetingPlacementCategoryTargetingPlacementCategories = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const categories = useAppSelector(state => state.consts.placementCategories);

  const options = React.useMemo(() => {
    return categories?.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }));
  }, [categories]);

  return (
    <ControlledSelectMultiple
      name='targeting.placementCategoryTargeting.placementCategories'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

LineitemTargetingPlacementCategoryTargetingPlacementCategories.displayName =
  'Lineitem.targeting.placementCategoryTargeting.placementCategories';

export { LineitemTargetingPlacementCategoryTargetingPlacementCategories };
