import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';
import { selectAllCustomTargetings } from 'store/targetings';

const LineitemTargetingsExclude = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const targetings = useAppSelector(selectAllCustomTargetings);

  const options = React.useMemo(() => {
    return targetings?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [targetings]);

  return (
    <ControlledSelectMultiple
      name='targetingsExclude'
      label='Exclude users'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

LineitemTargetingsExclude.displayName = 'Lineitem.targetingsExclude';

export { LineitemTargetingsExclude };
