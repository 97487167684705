import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from 'components/Router.PrivateRoute';
import {
  DASHBOARD,
  LOGIN,
  CAMPAIGN_LIST,
  CAMPAIGN_CREATE,
  CAMPAIGN_SHOW,
  CAMPAIGN_STATISTICS_APPS,
  CAMPAIGN_STATISTICS_WEBSITES,
  CAMPAIGN_EDIT,
  ADVERTISER_LIST,
  ADVERTISER_CREATE,
  ADVERTISER_EDIT,
  ADVERTISER_SHOW,
  CREATIVE_LIST,
  CREATIVE_SHOW,
  CREATIVE_CREATE,
  CREATIVE_EDIT,
  LINEITEM_LIST,
  LINEITEM_SHOW,
  LINEITEM_CREATE,
  LINEITEM_EDIT,
  TARGETING_LIST,
  TARGETING_CREATE,
  TARGETING_SHOW,
  TARGETING_EDIT,
  MODELSET_LIST,
  MODELSET_CREATE,
  MODELSET_SHOW,
  MODELSET_EDIT,
  OPTIMIZATION_LIST,
  OPTIMIZATION_SHOW,
  OPTIMIZATION_EDIT,
  DEAL_LIST,
  DEAL_SHOW,
  PROPOSAL_LIST,
  PROPOSAL_SHOW,
} from 'routes';
import {
  CAN_VIEW_CAMPAIGNS,
  CAN_EDIT_CAMPAIGNS,
  CAN_VIEW_MODELSETS,
  CAN_EDIT_MODELSETS,
  CAN_VIEW_TARGETINGS,
  CAN_EDIT_TARGETINGS,
  CAN_VIEW_ADVERTISERS,
  CAN_EDIT_ADVERTISERS,
  CAN_VIEW_FINALIZED_DEALS,
} from 'consts/permissions';

const KitchenSink = React.lazy(() => import('pages/KitchenSink'));
const Login = React.lazy(() => import('pages/Login'));

const CampaignList = React.lazy(() => import('pages/Campaign/Campaign.List'));
const CampaignShow = React.lazy(() => import('pages/Campaign/Campaign.Show'));
const CampaignCreate = React.lazy(() =>
  import('pages/Campaign/Campaign.Create'),
);
const CampaignEdit = React.lazy(() => import('pages/Campaign/Campaign.Edit'));
const CampaignAppsStatistics = React.lazy(() =>
  import('pages/Campaign/statistics/Campaign.Statistics.Apps'),
);
const CampaignWebsitesStatistics = React.lazy(() =>
  import('pages/Campaign/statistics/Campaign.Statistics.Websites'),
);

const AdvertiserList = React.lazy(() =>
  import('pages/Advertiser/Advertiser.List'),
);
const AdvertiserCreate = React.lazy(() =>
  import('pages/Advertiser/Advertiser.Create'),
);
const AdvertiserEdit = React.lazy(() =>
  import('pages/Advertiser/Advertiser.Edit'),
);
const AdvertiserShow = React.lazy(() =>
  import('pages/Advertiser/Advertiser.Show'),
);

const CreativeShow = React.lazy(() => import('pages/Creative/Creative.Show'));
const CreativeCreate = React.lazy(() =>
  import('pages/Creative/Creative.Create'),
);
const CreativeEdit = React.lazy(() => import('pages/Creative/Creative.Edit'));

const LineitemShow = React.lazy(() => import('pages/Lineitem/Lineitem.Show'));
const LineitemCreate = React.lazy(() =>
  import('pages/Lineitem/Lineitem.Create'),
);
const LineitemEdit = React.lazy(() => import('pages/Lineitem/Lineitem.Edit'));

const TargetingList = React.lazy(() =>
  import('pages/Targeting/Targeting.List'),
);
const TargetingCreate = React.lazy(() =>
  import('pages/Targeting/Targeting.Create'),
);
const TargetingEdit = React.lazy(() =>
  import('pages/Targeting/Targeting.Edit'),
);

const ModelsetList = React.lazy(() => import('pages/Modelset/Modelset.List'));
const ModelsetCreate = React.lazy(() =>
  import('pages/Modelset/Modelset.Create'),
);
const ModelsetEdit = React.lazy(() => import('pages/Modelset/Modelset.Edit'));

const OptimizationList = React.lazy(() =>
  import('pages/optimization/Optimization.List'),
);
const OptimizationEdit = React.lazy(() =>
  import('pages/optimization/Optimization.Edit'),
);

const DealList = React.lazy(() => import('pages/Deal/Deal.List'));
const DealShow = React.lazy(() => import('pages/Deal/Deal.Show'));

const ProposalList = React.lazy(() => import('pages/Proposal/Proposal.List'));
const ProposalShow = React.lazy(() => import('pages/Proposal/Proposal.Show'));

const Router = () => {
  return (
    <Switch>
      <Route path={LOGIN} exact component={Login} />
      <Route path='/kitchensink' exact component={KitchenSink} />
      <Redirect from={DASHBOARD} exact to={CAMPAIGN_LIST} />
      {/* kampanie */}
      <PrivateRoute
        path={CAMPAIGN_LIST}
        exact
        component={CampaignList}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CAMPAIGN_CREATE}
        exact
        component={CampaignCreate}
        permissions={[CAN_EDIT_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CAMPAIGN_SHOW}
        exact
        component={CampaignShow}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CAMPAIGN_EDIT}
        component={CampaignEdit}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CAMPAIGN_STATISTICS_APPS}
        component={CampaignAppsStatistics}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CAMPAIGN_STATISTICS_WEBSITES}
        component={CampaignWebsitesStatistics}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />

      {/* reklamodawcy */}
      <PrivateRoute
        path={ADVERTISER_LIST}
        exact
        component={AdvertiserList}
        permissions={[CAN_VIEW_ADVERTISERS]}
      />
      <PrivateRoute
        path={ADVERTISER_CREATE}
        exact
        component={AdvertiserCreate}
        permissions={[CAN_EDIT_ADVERTISERS]}
      />
      <PrivateRoute
        path={ADVERTISER_EDIT}
        component={AdvertiserEdit}
        permissions={[CAN_VIEW_ADVERTISERS]}
      />
      <PrivateRoute
        path={ADVERTISER_SHOW}
        component={AdvertiserShow}
        permissions={[CAN_VIEW_ADVERTISERS]}
      />

      {/* kreacje */}
      <Redirect from={CREATIVE_LIST} exact to={CAMPAIGN_SHOW} />
      <PrivateRoute
        path={CREATIVE_CREATE}
        exact
        component={CreativeCreate}
        permissions={[CAN_EDIT_CAMPAIGNS]}
      />

      <PrivateRoute
        path={CREATIVE_SHOW}
        exact
        component={CreativeShow}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={CREATIVE_EDIT}
        component={CreativeEdit}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      {/* lineitemy */}
      <Redirect from={LINEITEM_LIST} exact to={CAMPAIGN_SHOW} />
      <PrivateRoute
        path={LINEITEM_CREATE}
        component={LineitemCreate}
        permissions={[CAN_EDIT_CAMPAIGNS]}
      />
      <PrivateRoute
        path={LINEITEM_SHOW}
        exact
        component={LineitemShow}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      <PrivateRoute
        path={LINEITEM_EDIT}
        component={LineitemEdit}
        permissions={[CAN_VIEW_CAMPAIGNS]}
      />
      {/* listy targetowań */}
      <PrivateRoute
        path={TARGETING_LIST}
        exact
        component={TargetingList}
        permissions={[CAN_VIEW_TARGETINGS]}
      />
      <PrivateRoute
        path={TARGETING_CREATE}
        exact
        component={TargetingCreate}
        permissions={[CAN_EDIT_TARGETINGS]}
      />
      <PrivateRoute
        path={TARGETING_EDIT}
        exact
        component={TargetingEdit}
        permissions={[CAN_EDIT_TARGETINGS]}
      />
      <Redirect from={TARGETING_SHOW} exact to={TARGETING_EDIT} />
      {/* MODELSETY */}
      <PrivateRoute
        path={MODELSET_LIST}
        exact
        component={ModelsetList}
        permissions={[CAN_VIEW_MODELSETS]}
      />
      <PrivateRoute
        path={MODELSET_CREATE}
        exact
        component={ModelsetCreate}
        permissions={[CAN_EDIT_MODELSETS]}
      />
      <PrivateRoute
        path={MODELSET_EDIT}
        exact
        component={ModelsetEdit}
        permissions={[CAN_EDIT_MODELSETS]}
      />
      <Redirect from={MODELSET_SHOW} exact to={MODELSET_EDIT} />
      {/* optimization */}
      <PrivateRoute
        path={OPTIMIZATION_LIST}
        exact
        component={OptimizationList}
        permissions={[CAN_VIEW_MODELSETS]}
      />
      <PrivateRoute
        path={OPTIMIZATION_EDIT}
        exact
        component={OptimizationEdit}
        permissions={[CAN_EDIT_MODELSETS]}
      />
      <Redirect from={OPTIMIZATION_SHOW} exact to={OPTIMIZATION_EDIT} />
      {/* deals */}
      <PrivateRoute
        path={DEAL_LIST}
        exact
        component={DealList}
        permissions={[CAN_VIEW_FINALIZED_DEALS]}
      />
      <PrivateRoute
        path={DEAL_SHOW}
        component={DealShow}
        permissions={[CAN_VIEW_FINALIZED_DEALS]}
      />
      {/* proposals */}
      <PrivateRoute
        path={PROPOSAL_LIST}
        exact
        component={ProposalList}
        permissions={[CAN_VIEW_FINALIZED_DEALS]}
      />
      <PrivateRoute
        path={PROPOSAL_SHOW}
        component={ProposalShow}
        permissions={[CAN_VIEW_FINALIZED_DEALS]}
      />
    </Switch>
  );
};

export default Router;
