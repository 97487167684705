import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';
import { NAME } from './devicebrands.consts';
import adapter from './devicebrands.adapter';
import { fetchAll } from './devicebrands.actions';

const slice = createSlice({
  name: NAME,
  initialState: {
    ...adapter.getInitialState(),
    status: DATA_UNDEFINED,
    errors: NO_ERRORS,
    highlighted: [
      31, // Apple
      68, // Blackberry,
      428, // Samsung
      234, // Huawei
      543, // Xiaomi
      371, // Oppo
      293, // Lenovo
      295, // LG
      353, // Nokia
    ],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAll.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
