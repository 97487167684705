import { createSlice } from '@reduxjs/toolkit';
import { NAME } from './consts.consts';
import  { initialState } from './consts.adapter';
import { fetchAll } from './consts.actions.js';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAll.fulfilled]: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { reducer } = slice;
