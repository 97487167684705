import * as React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks';

const ExchangeName = ({ id }: { id?: string }): JSX.Element => {
  const exchanges = useAppSelector(state => state.consts.exchanges);

  const exchamgeName = exchanges?.find(
    ({ id: exchangeId }: { id: string }) => exchangeId === id,
  )?.name;

  return (
    <Flex alignItems='center'>
      <Text>{exchamgeName || id}</Text>
    </Flex>
  );
};

export default ExchangeName;
