import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './devices.consts';

export const fetchAll = createAsyncThunk(
  NAME + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/consts/devicemarketnames' });
    return response.data.map(
      ({
        marketname: name,
        marketname_code: code,
        brand_id: brandId,
        ...props
      }) => ({
        ...props,
        brandId,
        name,
        code,
      }),
    );
  }),
);
