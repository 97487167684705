import * as React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { ControlledInputArray } from 'components/ControlledFormFields';
import { InputTextWithAutocompletion } from 'components/InputTextWithAutocompletion';
import { useAppSelector } from 'hooks';

const LineitemTargetingSitePlacementTargetingPlacementList = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const domains = useAppSelector(state => state.consts.domains);
  const suggestions = React.useMemo(() => {
    if (!domains) return [];
    return domains.map((domain: any) => ({
      value: domain.id,
      search: domain.id,
      display: (
        <Flex alignItems='center'>
          <Image
            mr={2}
            src={`https://www.google.com/s2/favicons?domain=${domain.id}`}
          />
          <Box>
            <Text>{domain.id}</Text>
          </Box>
        </Flex>
      ),
    }));
  }, [domains]);

  return (
    <ControlledInputArray
      width='60ch'
      placeholder='example.com'
      component={InputTextWithAutocompletion}
      name='targeting.sitePlacementTargeting.placementList'
      label='Domains'
      suggestions={suggestions}
      isExpandable={true}
      {...props}
    />
  );
};

LineitemTargetingSitePlacementTargetingPlacementList.displayName =
  'Lineitem.targeting.sitePlacementTargeting.placementList';

export { LineitemTargetingSitePlacementTargetingPlacementList };
