import React from 'react';
import { ControlledCheckboxBoolean } from 'components';

const LineitemVideoAllowSkippable = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledCheckboxBoolean
    name='video.allowSkippable'
    label='Allow Skippable'
    checkedLabel='True'
    uncheckedLabel='False'
    w='100%'
    {...props}
  />
);

LineitemVideoAllowSkippable.displayName = 'Lineitem.video.allowSkippable';

export { LineitemVideoAllowSkippable };
