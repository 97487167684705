import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';

const LineitemTargetingGeoTargetingListPrecision = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const precisionAllowedValues = useAppSelector(
    state => state.consts.geoTargetingPrecision,
  );

  const options = React.useMemo(() => {
    return precisionAllowedValues?.map(
      ({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }),
    );
  }, [precisionAllowedValues]);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'targeting.geoTargetingList.enabled',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(!isActive);
  }, [isActive]);

  return (
    <ControlledSelect
      name='targeting.geoTargetingList.precision'
      label='Precision'
      options={options}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

LineitemTargetingGeoTargetingListPrecision.displayName =
  'Lineitem.targeting.geoTargetingList.precision';

export { LineitemTargetingGeoTargetingListPrecision };
