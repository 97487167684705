import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectAll } from 'store/modelsets';
import { useController, useFormContext } from 'react-hook-form';

const LineitemModelsetId = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const allModelsets = useAppSelector(selectAll);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: optimizationId },
  } = useController({
    name: 'optimizationId',
    control,
  });

  const options = React.useMemo(() => {
    if (!allModelsets || !optimizationId) return [];
    return allModelsets
      .filter(modelset => modelset.optimizationId === optimizationId)
      .map(({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }));
  }, [allModelsets, optimizationId]);

  React.useEffect(() => {
    setIsDisabled(!optimizationId);
  }, [optimizationId]);

  return (
    <ControlledSelect
      name='modelsetId'
      label='Modelset'
      options={options}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

LineitemModelsetId.displayName = 'Lineitem.modelsetId';

export { LineitemModelsetId };
