import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ControlledInputNumber } from 'components';

const CampaignTotalBudget = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'budgetPerLineitem',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(isActive);
  }, [isActive]);
  return (
    <ControlledInputNumber
      name='budget.totalBudget'
      label='Total Budget'
      min={0}
      precision={0}
      step={1}
      addon='$'
      isDisabled={isDisabled}
      {...props}
    />
  );
};

CampaignTotalBudget.displayName = 'Campaign.totalBudget';

export { CampaignTotalBudget };
