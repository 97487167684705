import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as userReducer, NAME as user } from './user';
import {
  reducer as campaignsReducer,
  REDUCER_KEY as campaigns,
} from './campaigns';
import {
  reducer as advertisersReducer,
  REDUCER_KEY as advertisers,
} from './advertisers';
import { reducer as creativesReducer, NAME as creatives } from './creatives';
import { reducer as lineitemsReducer, NAME as lineitems } from './lineitems';
import { reducer as modelsetsReducer, NAME as modelsets } from './modelsets';
import {
  reducer as optimizationsReducer,
  NAME as optimizations,
} from './optimizations';
import { reducer as targetingsReducer, NAME as targetings } from './targetings';
import { reducer as statisticsReducer, NAME as statistics } from './statistics';
import { reducer as settingsReducer, NAME as settings } from './settings';
import {
  reducer as devicebrandsReducer,
  NAME as devicebrands,
} from './devicebrands';
import { reducer as devicesReducer, NAME as devices } from './devices';
import { reducer as constsReducer, NAME as consts } from './consts';
import { reducer as historyReducer, REDUCER_KEY as history } from './history';
import {
  reducer as finalizedDealsReducer,
  REDUCER_KEY as finalizedDeals,
} from './deals';
import {
  reducer as porposalsReducer,
  REDUCER_KEY as proposals,
} from './proposals';

const rootReducer = combineReducers({
  [consts]: constsReducer,
  [user]: persistReducer({ key: [user], storage }, userReducer),
  [advertisers]: advertisersReducer,
  [campaigns]: campaignsReducer,
  [creatives]: creativesReducer,
  [lineitems]: lineitemsReducer,
  [modelsets]: modelsetsReducer,
  [optimizations]: optimizationsReducer,
  [targetings]: targetingsReducer,
  [statistics]: statisticsReducer,
  [settings]: settingsReducer,
  [devicebrands]: devicebrandsReducer,
  [devices]: devicesReducer,
  [history]: historyReducer,
  [finalizedDeals]: finalizedDealsReducer,
  [proposals]: porposalsReducer,
});
export default rootReducer;
