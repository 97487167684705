import { createSelector } from 'reselect';
import adapter from './targetings.adapter';
import { NAME } from './targetings.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const { selectIds, selectEntities, selectTotal, selectById } = selectors;
export const selectCurrentTargetingId = state => state[NAME].current;

export const selectAllTargetings = createSelector(
  state => selectors.selectAll(state),
  targetings => targetings || [],
);

export const selectAllAppsTargetings = createSelector(
  state => selectors.selectAll(state),
  targetings => targetings.filter(targeting => targeting.kind === 'app'),
);

export const selectAllDomainsTargetings = createSelector(
  state => selectors.selectAll(state),
  targetings => targetings.filter(targeting => targeting.kind === 'domain'),
);

export const selectAllGeoTargetings = createSelector(
  state => selectors.selectAll(state),
  targetings => targetings.filter(targeting => targeting.kind === 'geo'),
);

export const selectAllCustomTargetings = createSelector(
  state => selectors.selectAll(state),
  targetings => targetings.filter(targeting => targeting.kind === 'custom'),
);

export const selectTargeting = (state, targetingId) =>
  selectById(state, targetingId);

export const selectCurrentTargeting = createSelector(
  state => selectCurrentTargetingId(state),
  state => selectAllTargetings(state),
  (currentId, targeting) =>
    targeting.find(({ id }) => id === currentId) || undefined,
);

export const selectTargetingsAsOptions = createSelector(
  selectAllTargetings,
  allTargetings => allTargetings.map(({ id, name }) => ({ id, name })),
);
