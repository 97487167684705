import { mode } from '@chakra-ui/theme-tools';

const baseStyle = props => ({
  field: {
    display: 'grid',
    gridTemplateAreas: `
    "preview heading action"
    "preview details action"`,
    gridTemplateColumns: 'max-content 1fr max-content',
    borderRadius: 'md',
    alignItems: 'center',
    background: mode('white.100', 'dark.100')(props),
    color: mode('graphite.500', 'dark.300')(props),
    borderColor: 'graphite.400',
    borderWidth: '1px',
    _hover: {
      borderColor: 'graphite.500',
    },
    _focus: {
      borderColor: 'graphite.500',
      boxShadow: 'unset',
    },
  },
  heading: {
    gridArea: 'heading',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  details: {
    gridArea: 'details',
    lineHeight: 1,
  },
  action: { gridArea: 'action' },
  preview: {
    gridArea: 'preview',
    background: mode('white.100', 'dark.100')(props),
    borderRadius: 'md',
    borderColor: 'graphite.400',
    borderWidth: '1px',
    position: 'relative',
    overflow: 'hidden',
  },
  previewIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.25,
  },
});

const sizes = {
  sm: {
    field: {
      padding: 1,
      gridColumnGap: 1,
    },
    preview: {
      boxSize: 6,
    },
  },
  md: {
    field: {
      padding: 2,
      gridColumnGap: 2,
    },
    preview: {
      boxSize: 8,
    },
    heading: {
      fontSize: 'sm',
    },
    details: {
      fontSize: 'xs',
    },
  },
  lg: {
    field: {
      padding: 4,
      gridColumnGap: 4,
    },
    preview: {
      boxSize: 20,
    },
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
