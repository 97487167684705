import * as React from 'react';
import { useAppSelector } from 'hooks';
import { selectAllDomainsTargetings } from 'store/targetings';
import { ControlledSelect } from 'components';

const LineitemTargetingSitePlacementTargetingTargetingFile = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const allCampaigns = useAppSelector(selectAllDomainsTargetings);

  const options = React.useMemo(() => {
    return allCampaigns?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [allCampaigns]);

  return (
    <ControlledSelect
      name='targeting.sitePlacementTargeting.targetingFile'
      options={options}
      label='Targeting file'
      isCleareable
      {...props}
    />
  );
};

LineitemTargetingSitePlacementTargetingTargetingFile.displayName =
  'Lineitem.targeting.sitePlacementTargeting.targetingFile';

export { LineitemTargetingSitePlacementTargetingTargetingFile };
