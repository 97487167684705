import React from 'react';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const VALUES = [
  { name: 'Target selected', id: false },
  { name: 'Exclude selected', id: true },
];

const LineitemTargetingRegionExclude = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledRadioGroup
    name='targeting.region.exclude'
    values={VALUES}
    valueType='bool'
    {...props}
  />
);

LineitemTargetingRegionExclude.displayName =
  'Lineitem.targeting.region.exclude';

export { LineitemTargetingRegionExclude };
