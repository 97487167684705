import React from 'react';
import { ControlledCheckboxBoolean } from 'components';

const LineitemTargetingCollectingDataActive = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledCheckboxBoolean
    name='targeting.collectingDataActive'
    label='Collecting data'
    checkedLabel='Is enabled'
    uncheckedLabel='Is disabled'
    w='100%'
    {...props}
  />
);

LineitemTargetingCollectingDataActive.displayName =
  'Lineitem.targeting.collectingDataActive';

export { LineitemTargetingCollectingDataActive };
