import { mode } from '@chakra-ui/theme-tools';
export const ButtonStyles = {
  baseStyle: {
    fontWeight: 500,
  },
  sizes: {},
  variants: {
    primary: {
      bg: 'salmon.100',
      color: 'white.100',
      _hover: {
        bg: 'salmon.200',
        _disabled: {
          bg: 'graphite.100',
        },
      },
    },
    secondary: {
      bg: 'grass.200',
      color: 'white.100',
      _hover: {
        bg: 'graphite.600',
        _disabled: {
          bg: 'graphite.100',
        },
      },
    },
    tertiary: props => ({
      bg: mode('graphite.200', 'graphite.500')(props),
      color: mode('graphite.500', 'dark.300')(props),
      _hover: {
        bg: mode('graphite.300', 'graphite.600')(props),
        _disabled: {
          bg: 'graphite.100',
        },
      },
    }),
    danger: {
      bg: 'red.500',
      color: 'white.100',
    },
    unstyled: {
      display: 'inline-flex',
      fontWeight: 'bold',
    },
  },
  defaultProps: {},
};
