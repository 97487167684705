import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { selectCurrentCampaign } from 'store/campaigns';
import { selectCurrentCreative } from 'store/creatives';
import { selectCurrentLineitem } from 'store/lineitems';
import { selectById as selectModelsetById } from 'store/modelsets';
import { selectById as selectTargetingById } from 'store/targetings';
import { selectCurrentAdvertiser } from 'store/advertisers';
import { selectCurrentDeal } from 'store/deals';
import { selectCurrenProposal } from 'store/proposals';

import {
  CAMPAIGN_LIST,
  CAMPAIGN_SHOW,
  CREATIVE_LIST,
  CREATIVE_SHOW,
  LINEITEM_LIST,
  LINEITEM_SHOW,
  TARGETING_LIST,
  TARGETING_SHOW,
  MODELSET_LIST,
  MODELSET_SHOW,
  ADVERTISER_LIST,
  ADVERTISER_SHOW,
  DEAL_LIST,
  DEAL_SHOW,
  PROPOSAL_LIST,
  PROPOSAL_SHOW,
} from 'routes';

import {
  Optimizations,
  Optimization,
} from 'components/optimization/Breadcrumbs';

const Breadcrumbs = ({ children, ...props }) => (
  <Breadcrumb fontSize='sm' fontWeight='medium' separator='»' {...props}>
    {children}
  </Breadcrumb>
);
Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

const Campaigns = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(CAMPAIGN_LIST)}>
      Campaigns
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Campaign = ({ ...props }) => {
  const { id: campaignId, name } = useSelector(selectCurrentCampaign);
  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CAMPAIGN_SHOW, { campaignId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Advertisers = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(ADVERTISER_LIST)}>
      Advertisers
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Advertiser = ({ ...props }) => {
  const { id: advertiserId, name } = useSelector(selectCurrentAdvertiser);
  return (
    <BreadcrumbItem {...props}>
      {advertiserId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(ADVERTISER_SHOW, { advertiserId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Creatives = ({ ...props }) => {
  const { id: campaignId } = useSelector(selectCurrentCampaign);

  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CREATIVE_LIST, { campaignId })}
        >
          Creatives
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Creative = ({ ...props }) => {
  const { id: creativeId, campaignId, name } = useSelector(
    selectCurrentCreative,
  );
  return (
    <BreadcrumbItem {...props}>
      {creativeId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CREATIVE_SHOW, { campaignId, creativeId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Lineitems = ({ ...props }) => {
  const { id: campaignId } = useSelector(selectCurrentCampaign);

  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(LINEITEM_LIST, { campaignId })}
        >
          Lineitems
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Lineitem = ({ ...props }) => {
  const { id: lineitemId, campaignId, general: { name } = {} } = useSelector(
    selectCurrentLineitem,
  );
  return (
    <BreadcrumbItem {...props}>
      {campaignId && lineitemId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(LINEITEM_SHOW, { campaignId, lineitemId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Targetings = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(TARGETING_LIST)}>
      Targetings
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Targeting = ({ ...props }) => {
  const urlParams = useParams();
  const targetingId = Number.parseInt(urlParams.targetingId);
  const targetingObj = useSelector(state =>
    selectTargetingById(state, targetingId),
  );

  return (
    <BreadcrumbItem {...props}>
      <BreadcrumbLink
        as={Link}
        to={generatePath(TARGETING_SHOW, { targetingId })}
      >
        {targetingObj ? targetingObj.name : ''}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};

const Modelsets = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(MODELSET_LIST)}>
      Modelsets
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Modelset = ({ ...props }) => {
  const urlParams = useParams();
  const modelsetId = Number.parseInt(urlParams.modelsetId);
  const modelsetObj = useSelector(state =>
    selectModelsetById(state, modelsetId),
  );

  return (
    <BreadcrumbItem {...props}>
      <BreadcrumbLink
        as={Link}
        to={generatePath(MODELSET_SHOW, { modelsetId })}
      >
        {modelsetObj ? modelsetObj.name : ''}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};

const Deals = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(DEAL_LIST)}>
      Private Deals
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Deal = ({ ...props }) => {
  const { id: dealId, name } = useSelector(selectCurrentDeal);
  return (
    <BreadcrumbItem {...props}>
      {dealId ? (
        <BreadcrumbLink as={Link} to={generatePath(DEAL_SHOW, { dealId })}>
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Proposals = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(PROPOSAL_LIST)}>
      Proposals
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Proposal = ({ ...props }) => {
  const { id: proposalId, name } = useSelector(selectCurrenProposal);
  return (
    <BreadcrumbItem {...props}>
      {proposalId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(PROPOSAL_SHOW, { proposalId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const CurrentPage = ({ children, ...props }) => (
  <BreadcrumbItem {...props} isCurrentPage>
    <BreadcrumbLink>{children}</BreadcrumbLink>
  </BreadcrumbItem>
);
CurrentPage.propTypes = {
  children: PropTypes.node.isRequired,
};

Breadcrumbs.Campaigns = Campaigns;
Breadcrumbs.Campaign = Campaign;
Breadcrumbs.Advertisers = Advertisers;
Breadcrumbs.Advertiser = Advertiser;
Breadcrumbs.Creatives = Creatives;
Breadcrumbs.Creative = Creative;
Breadcrumbs.Lineitems = Lineitems;
Breadcrumbs.Lineitem = Lineitem;
Breadcrumbs.Targetings = Targetings;
Breadcrumbs.Targeting = Targeting;
Breadcrumbs.Modelsets = Modelsets;
Breadcrumbs.Modelset = Modelset;
Breadcrumbs.Optimizations = Optimizations;
Breadcrumbs.Optimization = Optimization;
Breadcrumbs.Deals = Deals;
Breadcrumbs.Deal = Deal;
Breadcrumbs.Proposals = Proposals;
Breadcrumbs.Proposal = Proposal;

Breadcrumbs.CurrentPage = CurrentPage;

export default Breadcrumbs;
