import {
  CREATIVE_FIELD,
  validationSchema,
  validationSchemaVideo,
} from 'components/Lineitem/Lineitem.schema';
import { max, startOfToday } from 'date-fns';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { createSelector } from 'reselect';
import {
  selectCurrentCampaign,
  selectCurrentCampaignId,
} from 'store/campaigns';
import {
  selectNonVideoCreativesOfCampaign,
  selectNonVideoCreativesOfCurrentCampaign,
  selectVideoCreativesOfCampaign,
  selectVideoCreativesOfCurrentCampaign,
} from 'store/creatives';
import adapter from './lineitems.adapter';
import { NAME } from './lineitems.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const { selectIds, selectEntities, selectTotal, selectById } = selectors;
export const selectCurrentLineitemId = state => state[NAME].current;
export const selectLineitem = selectById;

export const selectAll = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);

export const selectCurrentLineitem = createSelector(
  state => selectCurrentLineitemId(state),
  state => selectAll(state),
  (currentLineitemId, allLineitems) =>
    allLineitems.find(({ id }) => id === currentLineitemId),
);

export const selectLineitemsOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAll,
  (currentCampaignId, allLineitems) =>
    allLineitems.filter(
      lineitem => lineitem.campaignId === currentCampaignId,
    ) || [],
);

export const selectVideoLineitemsOfCurrentCampaign = createSelector(
  selectLineitemsOfCurrentCampaign,
  lineitems => lineitems.filter(({ general: { kind } }) => kind === 'video'),
);

export const selectNonVideoLineitemsOfCurrentCampaign = createSelector(
  selectLineitemsOfCurrentCampaign,
  lineitems => lineitems.filter(({ general: { kind } }) => kind === 'image'),
);

export const selectNumberOfLineitemsOfCurrentCampaign = createSelector(
  selectLineitemsOfCurrentCampaign,
  lineitems => lineitems.length,
);

export const selectNumberOfVideoLineitemsOfCurrentCampaign = createSelector(
  selectVideoLineitemsOfCurrentCampaign,
  lineitems => lineitems.length,
);

export const selectNumberOfNonVideoLineitemsOfCurrentCampaign = createSelector(
  selectNonVideoLineitemsOfCurrentCampaign,
  lineitems => lineitems.length,
);

export const selectIsThereGeoTargetedLineitemInCurrentCampaign = createSelector(
  selectLineitemsOfCurrentCampaign,
  lineitems => {
    const linetiem = lineitems.find(
      lineitem => lineitem.targeting.geoTargetingList.enabled,
    );
    if (!linetiem) return false;
    return true;
  },
);

export const selectLineitemFormData = (state, lineitemId) => {
  const lineitem = selectLineitem(state, lineitemId);
  const creativesOfCampaign =
    lineitem?.general?.kind === 'video'
      ? selectVideoCreativesOfCampaign(state, lineitem?.campaignId)
      : selectNonVideoCreativesOfCampaign(state, lineitem?.campaignId);
  if (!lineitem || !creativesOfCampaign) return undefined;
  if (creativesOfCampaign.length === 0) return undefined;

  const lineitemCreatives = creativesOfCampaign.map(creative => {
    const lineitemCreativeData = lineitem?.creatives?.find(
      lineitemCreative => lineitemCreative.creativeId === creative.id,
    );
    return {
      ...(lineitemCreativeData || CREATIVE_FIELD.getDefault()),
      creativeId: creative.id,
      kind: creative.kind,
    };
  });

  const creatieList = lineitemCreatives.map(creative => {
    if (creative?.cpc === null) creative.cpc = 1.0;
    if (creative?.cpm === null) creative.cpm = 1.0;
    if (creative?.cpv === null) creative.cpv = 1.0;
    return creative;
  });
  console.log('creatieList', creatieList);

  return {
    ...lineitem,
    creatives: creatieList,
  };
};

export const selectFormDataForNewLineitemOfCurrentCampaign = createSelector(
  selectCurrentCampaign,
  selectNonVideoCreativesOfCurrentCampaign,
  state => state.consts.creativeFormats, // TODO: do zamiany na selektor
  (campaign, creatives, formats) => {
    if (!campaign || !formats || !creatives) return undefined;
    if (creatives.length === 0) return undefined;

    const lineitem = validationSchema(isBudgetVisible()).getDefault();
    lineitem.campaignId = campaign.id;
    lineitem.general.timing.startDate = max([
      startOfToday(),
      campaign.startDate,
    ]);
    lineitem.general.timing.endDate = campaign.endDate;
    lineitem.creatives = creatives.map(creative => {
      const creativeId = creative.id;
      return {
        ...CREATIVE_FIELD.getDefault(),
        creativeId,
      };
    });

    return lineitem;
  },
);

export const selectFormDataForNewVideoLineitemOfCurrentCampaign = createSelector(
  selectCurrentCampaign,
  selectVideoCreativesOfCurrentCampaign,
  state => state.consts.creativeFormats, // TODO: do zamiany na selektor
  (campaign, creatives, formats) => {
    if (!campaign || !formats || !creatives) return undefined;
    if (creatives.length === 0) return undefined;

    const lineitem = validationSchemaVideo(isBudgetVisible()).getDefault();
    lineitem.campaignId = campaign.id;
    lineitem.general.timing.startDate = max([
      startOfToday(),
      campaign.startDate,
    ]);
    lineitem.general.timing.endDate = campaign.endDate;
    lineitem.creatives = creatives.map(creative => {
      const creativeId = creative.id;
      return {
        ...CREATIVE_FIELD.getDefault(),
        creativeId,
      };
    });

    return lineitem;
  },
);
