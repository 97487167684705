import { createSelector } from 'reselect';
import adapter from './optimizations.adapter';
import { NAME } from './optimizations.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const selectCurrentOptimizationId = state => state[NAME].current;
export const { selectIds, selectEntities, selectTotal, selectById } = selectors;

export const selectAll = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);

export const selectOptimizationsAsOptions = createSelector(
  selectAll,
  allOptimizations =>
    allOptimizations.map(({ id, optimizationName: name }) => ({ id, name })),
);
