import React from 'react';
import { Grid, Box, Checkbox, Text } from '@chakra-ui/react';
import useIsSelected from '../useIsSelected';
import withFilteringInput from 'hooks/withFilteringInput';
import { DEVICES_FIELD_NAME } from '../consts';

const ROW_LIMIT = 100;
interface RowProps {
  children?: React.ReactNode;
}

const Row = ({ children, ...props }: RowProps): JSX.Element => (
  <Grid templateAreas='"name"' templateColumns='1fr' {...props}>
    {children}
  </Grid>
);

interface DeviceProps {
  id: number;
  name: string;
  brandName: string;
}

const Device = ({ id, name, brandName }: DeviceProps): JSX.Element => {
  const { isSelected, handleSelectionChange } = useIsSelected(
    DEVICES_FIELD_NAME,
    id,
  );
  return (
    <Checkbox
      value={id}
      size='sm'
      isChecked={isSelected}
      onChange={handleSelectionChange}
    >
      <Row>
        <Box gridArea='name'>
          {brandName} {name}
        </Box>
      </Row>
    </Checkbox>
  );
};

interface DevicesProps {
  list: [];
  brandName: string;
}

const Devices = ({ list, brandName }: DevicesProps): JSX.Element => {
  const isOverLimit = list.length > ROW_LIMIT;
  const devices = isOverLimit ? list.slice(0, ROW_LIMIT) : list;
  return (
    <>
      {isOverLimit && (
        <Text mt={2} mb={4} fontSize='xs'>
          Showing only {ROW_LIMIT} devices; use filter input to specify results.
        </Text>
      )}
      <Grid gap={2}>
        {devices.map((device: any) => (
          <Device key={device.id} brandName={brandName} {...device} />
        ))}
      </Grid>
    </>
  );
};

export const DevicesWithFilteringInput = withFilteringInput(Devices, 'list');
export default Devices;
