import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { NAME } from './modelsets.consts';

export const fetchAllModelsets = createAsyncThunk(
  NAME + '/list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adserver({ url: '/modelsets' });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchById = createAsyncThunk(
  NAME + '/get',
  async ({ modelsetId }, { rejectWithValue }) => {
    try {
      const response = await adserver({ url: `/modelset/${modelsetId}` });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const create = createAsyncThunk(
  NAME + '/create',
  async ({ modelsetObj }, { rejectWithValue }) => {
    try {
      const response = await adserver({
        url: '/modelset/create',
        method: 'POST',
        data: modelsetObj,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const update = createAsyncThunk(
  NAME + '/update',
  async ({ modelsetId, modelsetObj }, { rejectWithValue }) => {
    try {
      const response = await adserver({
        url: `/modelset/${modelsetId}`,
        method: 'PATCH',
        data: modelsetObj,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const remove = createAsyncThunk(
  NAME + '/delete',
  async ({ modelsetId }, { rejectWithValue }) => {
    try {
      await adserver({
        url: '/modelset/' + modelsetId,
        method: 'DELETE',
      });
      return modelsetId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const select = createAsyncThunk(
  NAME + '/select',
  async ({ creativeId }, { getState, dispatch }) => {
    if (!creativeId) return NO_CURRENT_ENTITY;

    const currentlySelected = getState()[NAME].selected;
    if (creativeId !== currentlySelected) {
      await dispatch(fetchById({ creativeId }));
    }

    return creativeId;
  },
);

export const removeAllModelsets = () => ({ type: NAME + '/removeAll' });
