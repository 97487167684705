import { createAsyncThunk } from '@reduxjs/toolkit';
import { EDITABLE_FIELDS } from 'consts/campaign';
import { differenceInDays } from 'date-fns';
import {
  normalizeDateForApi,
  normalizeDateFromApi,
} from 'helpers/datetimeHelpers';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { merge, pick } from 'lodash';
import adserver from 'services/adserver';
import { getHistoryOfCampaign } from 'store/history/history.actions';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './campaigns.consts';
import { selectById, selectCurrentCampaignId } from './campaigns.selectors';

function normalizeApiReponse(campaign) {
  const startDate = normalizeDateFromApi(campaign.startDate);
  const endDate = normalizeDateFromApi(campaign.endDate);

  const totalDurationInDays = differenceInDays(endDate, startDate) || 1;
  const remainingDurationInDays = Math.max(
    0,
    differenceInDays(endDate, new Date()),
  );
  const durationProgressAsPercentage = Math.round(
    ((totalDurationInDays - remainingDurationInDays) / totalDurationInDays) *
      100,
  );

  return {
    ...campaign,
    startDate,
    endDate,
    durationProgressAsPercentage,
    totalDurationInDays,
    remainingDurationInDays,
  };
}

function normalizeApiRequest(data) {
  const editableFieldsOnly = pick(data, Object.keys(EDITABLE_FIELDS));

  const startDate = normalizeDateForApi(editableFieldsOnly.startDate);
  const endDate = normalizeDateForApi(editableFieldsOnly.endDate);
  const totalBudget = editableFieldsOnly.budget?.totalBudget;

  return {
    ...editableFieldsOnly,
    startDate,
    endDate,
    totalBudget,
  };
}

function normalizeBudget(data) {
  return {
    totalBudget: data?.totalBudget,
    spentBudget: data?.spent,
    remainingBudget: data?.remainingBudget,
  };
}

/**
 * wspólny handler dla akcji akcji aktualizujących dane kampanii
 *
 * @param {*} { campaignId }
 * @param {*} thunkApi
 * @return {*} campaignObj
 */
const fetchByIdHandler = async ({ campaignId }) => {
  const { data: campaign } = await adserver({ url: `/campaign/${campaignId}` });
  const budget = isBudgetVisible()
    ? await handleFetchCampaignBuget({ campaignId })
    : [];

  return {
    ...normalizeApiReponse(campaign),
    ...normalizeBudget(budget),
  };
};

/**
 * wspólny handler dla akcji wykorzystujących metodę api do aktualizacji kampanii
 *
 * @param {*} values
 * @param {*} thunkApi
 * @return {*} campaignObj
 */
const updateHandler = async (values, thunkAPI) => {
  const { id: campaignId } = values;
  if (!campaignId) throw new Error('no id provided');

  const response = await adserver({
    url: '/campaign/' + campaignId,
    method: 'PATCH',
    data: normalizeApiRequest(values),
  });
  thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
  return normalizeApiReponse(response.data);
};

const handleFetchCampaignBuget = async ({ campaignId }) => {
  try {
    const { data: budget } = await adserver({
      url: `/campaign/${campaignId}/budget`,
    });
    return budget;
  } catch (error) {
    return {};
  }
};

export const fetchAll = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/campaigns' });
    return response.data.map(campaign => normalizeApiReponse(campaign));
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/fetchById',
  withErrorHandling(fetchByIdHandler),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async values => {
    const response = await adserver({
      url: '/campaign/',
      method: 'POST',
      data: normalizeApiRequest(values),
    });
    return normalizeApiReponse(response.data);
  }),
);

export const fetchCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchCurrentCampaign',
  // eslint-disable-next-line no-unused-vars
  withErrorHandling(async (_, thunkAPI) => {
    const campaignId = selectCurrentCampaignId(thunkAPI.getState());
    if (!campaignId)
      return thunkAPI.rejectWithValue('currentCampaignId is unavailable');

    return await fetchByIdHandler({ campaignId });
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(updateHandler),
);

export const toggleStatus = createAsyncThunk(
  REDUCER_KEY + '/toggleStatus',
  withErrorHandling(async (campaignId, thunkAPI) => {
    const campaign = selectById(thunkAPI.getState(), campaignId);
    const isActive = !campaign.status.isActive;
    await adserver({
      url: `/campaign/${campaignId}/status`,
      method: 'POST',
      data: {
        isActive,
      },
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
    return {
      ...campaign,
      status: {
        isActive,
      },
    };
  }),
);

export const toggleLivepreviev = createAsyncThunk(
  REDUCER_KEY + '/livepreview/toggle',
  async ({ campaignId }, thunkAPI) => {
    const campaign = selectById(thunkAPI.getState(), campaignId);
    const changes = {
      status: {
        isLivepreviewActive: !campaign.status.isLivepreviewActive,
      },
    };

    return await updateHandler({ campaignId, changes }, thunkAPI);
  },
);

export const fetchBudget = createAsyncThunk(
  REDUCER_KEY + '/budget/fetch',
  withErrorHandling(async ({ campaignId }) => {
    const { data } = await adserver({
      url: `/campaign/${campaignId}/budget`,
    });
    return {
      id: campaignId,
      changes: normalizeBudget(data),
    };
  }),
);

export const updateBudget = createAsyncThunk(
  REDUCER_KEY + '/budget/update',
  withErrorHandling(async ({ entitiyId, budgetObj }) => {
    const { data } = await adserver({
      url: `/campaign/${entitiyId}/budget`,
      method: 'PATCH',
      data: budgetObj,
    });
    return {
      id: entitiyId,
      changes: normalizeBudget(data),
    };
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(async ({ campaignId }) => {
    await adserver({
      url: '/campaign/' + campaignId,
      method: 'DELETE',
    });
    return campaignId;
  }),
);

export const deploy = createAsyncThunk(
  REDUCER_KEY + '/deploy',
  withErrorHandling(async ({ campaignId }, thunkAPI) => {
    await adserver({
      url: `/campaign/${campaignId}/deploy`,
      method: 'POST',
    });

    const campaign = selectById(thunkAPI.getState(), campaignId);
    return merge({}, campaign, {
      bidder: {
        upToDate: true,
      },
    });
  }),
);

export const setCurrent = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async campaignId => {
    if (!campaignId) return;
    const campaign = await fetchByIdHandler({ campaignId });
    return campaign;
  }),
);

export const removeAllCampaigns = () => ({ type: REDUCER_KEY + '/removeAll' });

export const excludePlacementsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/excludePlacementsForCurrentCampaign',
  withErrorHandling(async ({ placementType, placementList }, thunkAPI) => {
    const campaignId = selectCurrentCampaignId(thunkAPI.getState());
    await adserver({
      url: `/campaign/${campaignId}/exclude_placements`,
      method: 'PATCH',
      data: { placementType, placementList },
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
  }),
);

export const includePlacementsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/includePlacementsForCurrentCampaign',
  withErrorHandling(async ({ placementType, placementList }, thunkAPI) => {
    const campaignId = selectCurrentCampaignId(thunkAPI.getState());
    await adserver({
      url: `/campaign/${campaignId}/exclude_placements`,
      method: 'PATCH',
      data: { placementType, placementList, undo: true },
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
  }),
);

export const getReportForCurrentCampaing = createAsyncThunk(
  REDUCER_KEY + '/getReportForCurrentCampaing',
  withErrorHandling(async ({ campaignId }) => {
    const { data } = await adserver({
      url: `/campaign/${campaignId}/report`,
      method: 'GET',
      responseType: 'blob',
    });
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `report_${campaignId}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }),
);
