import * as React from 'react';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const VALUES = [
  { name: 'Target selected', id: false },
  { name: 'Exclude selected', id: true },
];

const LineitemTargetingUserCategoryTargetingExclude = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledRadioGroup
    name='targeting.userCategoryTargeting.exclude'
    values={VALUES}
    valueType='bool'
    {...props}
  />
);

LineitemTargetingUserCategoryTargetingExclude.displayName =
  'Lineitem.targeting.userCategoryTargeting.exclude';

export { LineitemTargetingUserCategoryTargetingExclude };
