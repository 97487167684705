import * as React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
  InputGroup,
  Input,
  StylesProvider,
  useMultiStyleConfig,
  NumberInputStepperProps,
  InputLeftElement,
} from '@chakra-ui/react';

interface InputNumberProps {
  addon?: string;
  isDisabled?: boolean;
}

const NumberInputGroupStepper = (props: NumberInputStepperProps) => {
  const numberInputStyles = useMultiStyleConfig('NumberInput', props); // maybe {} instead of props would be better?
  return (
    <StylesProvider value={numberInputStyles}>
      <NumberInputStepper {...props} />
    </StylesProvider>
  );
};

export const InputNumber = ({
  addon,
  isDisabled,
  ...props
}: NumberInputProps & InputNumberProps): JSX.Element => {
  return (
    <NumberInput as={InputGroup} isDisabled={isDisabled} {...props}>
      {addon && <InputLeftElement>{addon}</InputLeftElement>}
      <Input as={NumberInputField} isDisabled={isDisabled} />
      <NumberInputGroupStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputGroupStepper>
    </NumberInput>
  );
};
