import {
  selectAgeDemographics,
  selectApps,
  selectCities,
  selectCountries,
  selectDomains,
  selectEventTypes,
  selectExchanges,
  selectFrequencyCappingPeriods,
  selectGenderDemographics,
  selectGeoTargetingPrecisions,
  selectOperatingSystems,
  selectPlacementCategories,
  selectProvinces,
  selectTargetingLanguages,
  selectTrafficSources,
  selectUserCategories,
  selectWeekdays,
} from 'store/consts';
import { selectModelsetsAsOptions } from 'store/modelsets';
import { selectOptimizationsAsOptions } from 'store/optimizations';
import { selectTargetingsAsOptions } from 'store/targetings';
import * as Yup from 'yup';
import {
  selectVideoPlacementTypes,
  selectVideoPlaybackMethodTypes,
  selectVideoStartDelayTypes,
} from './../../store/consts/consts.selectors';

const READ_ONLY_FIELDS = {
  id: Yup.number().integer().required(),
  campaignId: Yup.number().integer().required(),
  status: Yup.object().shape({
    isActive: Yup.bool().required().label('Status: is active'),
  }),
};

/**
 * pojedyncza kreacja z listy przypisanej do lineitema
 */
export const CREATIVE_FIELD = Yup.object().shape({
  creativeId: Yup.number().integer().required().label('id'),
  isSelected: Yup.bool().required().default(false).label('Is selected'),
  cpm: Yup.number()
    .min(0.01)
    .default(1.0)
    .label('CPM')
    .nullable()
    .typeError('Field cannot be empty'),
  cpc: Yup.number()
    .min(0.01)
    .default(1.0)
    .label('CPC')
    .nullable()
    .typeError('Field cannot be empty'),
  cpv: Yup.number()
    .min(0.01)
    .default(1.0)
    .label('CPV')
    .nullable()
    .typeError('Field cannot be empty'),
});

export const EVENT_PLAN_TYPES = ['total', 'daily'];
export const EVENT_PLAN_PACE_TYPES = ['uniform', 'asap', 'ahead'];

export const EDITABLE_FIELDS = {
  targeting: Yup.object().shape({
    operatingSystem: Yup.array(Yup.string())
      .required()
      .default(['android', 'ios'])
      .label('Operating System')
      .meta({ optionsSelector: selectOperatingSystems }),
    exchange: Yup.array(Yup.string())
      .required()
      .default(['adx', 'adocean'])
      .label('Exchange')
      .meta({ optionsSelector: selectExchanges }),
    country: Yup.array(Yup.number())
      .required()
      .min(1)
      .default([1])
      .label('Country')
      .meta({ optionsSelector: selectCountries }),
    trafficSource: Yup.array(Yup.string())
      .required()
      .default(['site'])
      .label('Traffic Source')
      .meta({ optionsSelector: selectTrafficSources }),
    city: Yup.object().shape({
      list: Yup.array(Yup.string())
        .required()
        .default([])
        .label('City: list')
        .meta({ optionsSelector: selectCities }),
      exclude: Yup.bool().required().default(false).label('City: exclude'),
    }),
    region: Yup.object().shape({
      list: Yup.array(Yup.string())
        .required()
        .default([])
        .label('Region: list')
        .meta({ optionsSelector: selectProvinces }),
      exclude: Yup.bool().required().default(false).label('Region: exclude'),
    }),
    language: Yup.array(Yup.string())
      .default([])
      .label('Language')
      .meta({ optionsSelector: selectTargetingLanguages }),
    geoTargetingList: Yup.object().shape({
      enabled: Yup.bool()
        .required()
        .default(false)
        .label('Geotargeting List: is enabled'),
      precision: Yup.number()
        .required()
        .default(2)
        .label('Geotargeting List: precision')
        .meta({ optionsSelector: selectGeoTargetingPrecisions }),
      targeting: Yup.number()
        .integer()
        .nullable(true)
        .label('Geotargeting List: targeting id'),
    }),
    deviceBrand: Yup.array(Yup.number().integer())
      .required()
      .default([])
      .label('Device Brand')
      .meta({ optionsSelector: undefined }),
    deviceMarketname: Yup.array(Yup.number().integer())
      .required()
      .default([])
      .label('Device Marketname')
      .meta({ optionsSelector: undefined }),
    sitePlacementTargeting: Yup.object().shape({
      placementList: Yup.array()
        .of(Yup.string().min(1).label('Website'))
        .required()
        .default([])
        .label('Site Placement Targeting: websites')
        .meta({ optionsSelector: selectDomains }),
      targetingFile: Yup.string()
        .nullable(true)
        .default(null)
        .label('Site Placement Targeting: targeting file')
        .meta({ optionsSelector: undefined }),
      includePlacement: Yup.bool()
        .required()
        .default(true)
        .label('Site Placement Targeting: include placement'),
    }),
    appPlacementTargeting: Yup.object().shape({
      placementList: Yup.array()
        .of(Yup.string().min(1).label('Packagename'))
        .required()
        .default([])
        .label('App Placement Targeting: packagenames')
        .meta({ optionsSelector: selectApps }),
      targetingFile: Yup.string()
        .nullable(true)
        .default(null)
        .label('App Placement Targeting: targeting file')
        .meta({ optionsSelector: undefined }),
      includePlacement: Yup.bool()
        .required()
        .default(true)
        .label('App Placement Targeting: include placement'),
    }),
    placementCategoryTargeting: Yup.object().shape({
      placementCategories: Yup.array()
        .of(Yup.number().integer().min(1))
        .required()
        .default([])
        .label('Placement Categories')
        .meta({ optionsSelector: selectPlacementCategories }),
      exclude: Yup.bool()
        .required()
        .default(false)
        .label('Placement Categories: exclude'),
    }),
    userCategoryTargeting: Yup.object().shape({
      userCategories: Yup.array()
        .of(Yup.string().min(1))
        .required()
        .default([])
        .label('User Categories')
        .meta({ optionsSelector: selectUserCategories }),
      exclude: Yup.bool()
        .required()
        .default(false)
        .label('User Categories: exclude'),
    }),
    campaignRetargeting: Yup.object().shape({
      campaignIds: Yup.array(Yup.number().integer())
        .required()
        .default([])
        .label('Campaign Retargeting: campaignIds')
        .meta({ optionsSelector: undefined }),
      targetingType: Yup.string()
        .required()
        .default('clicks')
        .label('Campaign Retargeting: targeting type')
        .meta({ optionsSelector: selectEventTypes }),
    }),
    collectingDataActive: Yup.bool()
      .required()
      .default(false)
      .label('Collecting Data Active'),
    demographics: Yup.object().shape({
      age: Yup.array(Yup.string())
        .default([])
        .label('Demographic Targeting: age')
        .meta({ optionsSelector: selectAgeDemographics }),
      gender: Yup.array(Yup.string())
        .default([])
        .label('Demographic Targeting: gender')
        .meta({ optionsSelector: selectGenderDemographics }),
    }),
  }),
  targetingsExclude: Yup.array(Yup.number().integer())
    .required()
    .default([])
    .label('Targetings Exclude')
    .meta({ optionsSelector: selectTargetingsAsOptions }),
  targetingsInclude: Yup.array(Yup.number().integer())
    .required()
    .default([])
    .label('Targetings Inlude')
    .meta({ optionsSelector: selectTargetingsAsOptions }),
  modelsetId: Yup.number()
    .integer()
    .label('Modelset')
    .meta({ optionsSelector: selectModelsetsAsOptions }),
  optimizationId: Yup.number()
    .integer()
    .required()
    .label('Optimization')
    .meta({ optionsSelector: selectOptimizationsAsOptions }),
  creatives: Yup.array()
    .of(CREATIVE_FIELD)
    .required()
    .min(1)
    .default([])
    .label('Creatives'),
};

export const EDITABLE_BUDGET_FIELDS = {
  totalBudget: Yup.number().default(0).label('Lineitem Budget'),
};

export const GENERAL_FIELDS = {
  name: Yup.string().required().label('Lineitem Name').default(''),
  eventPlan: Yup.object().shape({
    eventPlanType: Yup.mixed()
      .oneOf(EVENT_PLAN_TYPES)
      .default(EVENT_PLAN_TYPES[0])
      .required()
      .label('Event Plan Type'),
    paceType: Yup.mixed()
      .oneOf(EVENT_PLAN_PACE_TYPES)
      .default(EVENT_PLAN_PACE_TYPES[0])
      .required()
      .label('Pace Type'),
    eventCount: Yup.number()
      .integer()
      .required()
      .min(1)
      .default(1000)
      .label('Event Count')
      .typeError('Field cannot be empty'),
    eventType: Yup.string()
      .required()
      .default('clicks')
      .label('Event Type')
      .meta({ optionsSelector: selectEventTypes }),
  }),
  minimalCtr: Yup.object().shape({
    isActive: Yup.bool()
      .required()
      .default(false)
      .label('Minimal CTR: is active'),
    value: Yup.number()
      .required()
      .min(0.01)
      .default(0.1)
      .label('Minimal CTR: value')
      .typeError('Field cannot be empty'),
  }),
  privateMarketplace: Yup.array()
    .of(Yup.number().min(1).label('Marketplace'))
    .required()
    .default([])
    .label('Private Marketplace'),
  timing: Yup.object().shape({
    startDate: Yup.date().required().label('Start Date'),
    endDate: Yup.date().required().label('End Date'),
    dailyTiming: Yup.array(Yup.number().integer())
      .required()
      .min(1)
      .default([...Array(7)].map((_, i) => i))
      .label('Daily Timing')
      .meta({ optionsSelector: selectWeekdays }),
    hourlyTiming: Yup.array(Yup.number().integer())
      .required()
      .min(2)
      .max(2)
      .default([0, 24])
      .label('Hourly Timing')
      .typeError('Field cannot be empty'),
  }),
  frequencyCapping: Yup.object().shape({
    enabled: Yup.bool()
      .required()
      .default(false)
      .label('Frequency Capping: is enabled'),
    count: Yup.number()
      .required()
      .min(0)
      .default(1)
      .label('Frequency Capping: count')
      .typeError('Field cannot be empty'),
    period: Yup.string()
      .default('day')
      .label('Frequency Capping: period')
      .meta({ optionsSelector: selectFrequencyCappingPeriods }),
  }),
};

export const GENERAL_BUDGET_FIELDS = {
  dailyBudget: Yup.number().required().min(1).default(30).label('Daily Budget'),
};

export const EDITABLE_VIDEOS_FIELDS = {
  video: Yup.object().shape({
    startDelay: Yup.array(Yup.number().integer())
      .default([])
      .label('Start Delay')
      .meta({ optionsSelector: selectVideoStartDelayTypes }),
    allowSkippable: Yup.bool().default(true).label('Allow Skippable'),
    playbackMethod: Yup.array(Yup.number().integer())
      .default([])
      .label('Playback Method')
      .meta({ optionsSelector: selectVideoPlaybackMethodTypes }),
    placementTypes: Yup.array(Yup.number().integer())
      .default([])
      .label('Placement Types')
      .meta({ optionsSelector: selectVideoPlacementTypes }),
  }),
};

export const HISTORY_FIELDS = {
  viewability: Yup.number().default(0).label('Viewability'),
  viewability_enabled: Yup.bool().default(false).label('Viewability Enabled'),
};

export const TYPE_FIELD = {
  kind: Yup.string().required().label('Kind').default('image'),
};

export const TYPE_VIDEO_FIELD = {
  kind: Yup.string().required().label('Kind').default('video'),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  ...EDITABLE_FIELDS,
  general: Yup.object().shape({
    ...GENERAL_FIELDS,
    ...TYPE_FIELD,
  }),
});
export const FORM_VALIDATION_SCHEMA_VIDEOS = Yup.object().shape({
  ...EDITABLE_FIELDS,
  ...EDITABLE_VIDEOS_FIELDS,
  general: Yup.object().shape({
    ...GENERAL_FIELDS,
    ...TYPE_VIDEO_FIELD,
  }),
});

export const validationSchema = (isBudgetVisible: boolean | undefined): any => {
  return Yup.object().shape({
    ...EDITABLE_FIELDS,
    ...(isBudgetVisible ? EDITABLE_BUDGET_FIELDS : {}),
    general: Yup.object().shape({
      ...GENERAL_FIELDS,
      ...TYPE_FIELD,
      ...(isBudgetVisible ? GENERAL_BUDGET_FIELDS : {}),
    }),
  });
};

export const validationSchemaVideo = (
  isBudgetVisible: boolean | undefined,
): any => {
  return Yup.object().shape({
    ...EDITABLE_FIELDS,
    ...EDITABLE_VIDEOS_FIELDS,
    ...(isBudgetVisible ? EDITABLE_BUDGET_FIELDS : {}),
    general: Yup.object().shape({
      ...GENERAL_FIELDS,
      ...(isBudgetVisible ? GENERAL_BUDGET_FIELDS : {}),
      ...TYPE_VIDEO_FIELD,
    }),
  });
};

export const lineitemSchema = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
  ...EDITABLE_BUDGET_FIELDS,
  ...EDITABLE_VIDEOS_FIELDS,
  ...HISTORY_FIELDS,
  general: Yup.object().shape({
    ...GENERAL_FIELDS,
    ...TYPE_VIDEO_FIELD,
  }),
});

export type LineitemInterface = Yup.InferType<typeof lineitemSchema>;
