import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import AuthService from 'services/auth';
import { removeAllAdvertisers } from '../advertisers';
import { removeAllCampaigns } from '../campaigns';
import { removeAllCreatives } from '../creatives';
import { removeAllDeals } from '../deals';
import { removeAllLineitems } from '../lineitems';
import { removeAllModelsets } from '../modelsets';
import { removeAllOptimizations } from '../optimizations';
import { removeAllProposals } from '../proposals';
import { removeAllStatistics } from '../statistics';
import { removeAllTargetings } from '../targetings';
import { NAME } from './user.consts';
import { normalizeUserData } from './user.utils';

export const login = createAsyncThunk(
  NAME + '/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const {
        data: { token },
      } = await AuthService.login({ username, password });
      const { data: user } = await AuthService.userProfile({ token });
      return { ...normalizeUserData(user), token };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk(
  NAME + '/logout',
  async (_, { rejectWithValue, getState, dispatch }) => {
    function clearEverything() {
      dispatch(removeAllCampaigns());
      dispatch(removeAllCreatives());
      dispatch(removeAllLineitems());
      dispatch(removeAllModelsets());
      dispatch(removeAllOptimizations());
      dispatch(removeAllStatistics());
      dispatch(removeAllTargetings());
      dispatch(removeAllDeals());
      dispatch(removeAllProposals());
      dispatch(removeAllAdvertisers());
    }

    try {
      const token = getState().user.token;
      const response = await AuthService.logout({ token });
      clearEverything();
      return response.data;
    } catch (error) {
      clearEverything();
      return rejectWithValue(error);
    }
  },
);

export const ping = createAsyncThunk(
  NAME + '/ping',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = getState().user.token;
      const response = await adserver({
        url: '/user/me',
        headers: { SpicyTimeout: 'continue' },
      });
      return { ...normalizeUserData(response.data), token };
    } catch (error) {
      dispatch(logout());
      return rejectWithValue(error);
    }
  },
);
