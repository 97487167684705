import * as Yup from 'yup';
import { startOfToday, endOfNextWeek } from 'consts/dates';

export const READ_ONLY_FIELDS = {
  id: Yup.number().integer(),
  status: Yup.object().shape({
    isActive: Yup.bool().required().default(false),
  }),
  spentBudget: Yup.number(),
  remainingBudget: Yup.number(),
};

export const EDITABLE_FIELDS = {
  name: Yup.string().required().default(''),
  startDate: Yup.date().default(startOfToday),
  endDate: Yup.date().default(endOfNextWeek),
  iabCategory: Yup.string().required().default(''),
  advertiserDomain: Yup.string().required().default(''),
  advertiser: Yup.number().integer(),
  restrictedCategory: Yup.array(Yup.number().integer()).required().default([]),
  budget: Yup.object().shape({ totalBudget: Yup.number().required() }),
  margin: Yup.object().shape({
    percentValue: Yup.number().integer(),
  }),
  budgetPerLineitem: Yup.bool().required().default(false),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const MODEL_VALIDATION_SCHEMA = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
});
