import { createAsyncThunk } from '@reduxjs/toolkit';
import { differenceInDays, isBefore, startOfToday } from 'date-fns';
import {
  normalizeDateForApi,
  normalizeDateFromApi,
} from 'helpers/datetimeHelpers';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { isArray, merge, mergeWith } from 'lodash';
import adserver from 'services/adserver';
import {
  fetchBudget as fetchCampaignBudget,
  selectById as selectCampaignById,
  selectCurrentCampaignId,
} from 'store/campaigns';
import { fetchAll as fetchAllCreatives } from 'store/creatives';
import { getHistoryOfCampaign } from 'store/history/history.actions';
import { withErrorHandling } from 'store/wrappers';
import {
  fetchTotalStatsForAppsInCurrentCampaign,
  fetchTotalStatsForWebsitesInCurrentCampaign,
} from '../statistics/statistics.actions';
import { NAME } from './lineitems.consts';
import { selectById, selectCurrentLineitemId } from './lineitems.selectors';

function normalizeApiReponse(data) {
  const startDate = normalizeDateFromApi(data.general.timing.startDate);
  const endDate = normalizeDateFromApi(data.general.timing.endDate);

  const totalDurationInDays = differenceInDays(endDate, startDate) || 1;
  const remainingDurationInDays = Math.max(
    0,
    differenceInDays(endDate, new Date()),
  );
  const durationProgressAsPercentage = Math.max(
    0,
    Math.round(
      ((totalDurationInDays - remainingDurationInDays) / totalDurationInDays) *
        100,
    ),
  );

  return merge({}, data, {
    budgetProgressAsPercentage: 0,
    durationProgressAsPercentage,
    totalDurationInDays,
    remainingDurationInDays,
    name: data.general.name,
    general: { timing: { startDate, endDate } },
  });
}

function normalizeApiRequest(lineitemObj) {
  const startDate = normalizeDateForApi(lineitemObj.general.timing.startDate);
  const endDate = normalizeDateForApi(lineitemObj.general.timing.endDate);

  // FIXME: formularz z checkboxami zwraca duplikaty wartosci; tymczasowy workaround
  const dailyTiming = [...new Set(lineitemObj.general.timing.dailyTiming)];

  return merge({}, lineitemObj, {
    general: {
      timing: { startDate, endDate, dailyTiming },
    },
  });
}

function normalizeBudget(data) {
  const budgetProgressAsPercentage = Math.max(
    0,
    Math.round((data?.spent / data?.totalBudget) * 100),
  );
  return {
    budgetProgressAsPercentage,
    totalBudget: data?.totalBudget,
    spentBudget: data?.spent,
    remainingBudget: data?.remainingBudget,
  };
}

/**
 * wspólny handler dla akcji pobierających dane pojedynczego lineitema
 *
 * @param {*} { campaignId, lineitemId }
 * @param {*} thunkApi
 * @return {*} lineitem
 */
const handleFetchLineitemForCampaign = async ({ campaignId, lineitemId }) => {
  const { data: lineitem } = await adserver({
    url: `/campaign/${campaignId}/lineitem/${lineitemId}`,
  });
  const budget = isBudgetVisible()
    ? await handleFetchLineitemBuget({ campaignId, lineitemId })
    : [];

  return { ...normalizeApiReponse(lineitem), ...normalizeBudget(budget) };
};

/**
 * wspólny handler dla akcji pobierających dane wszystkich lineitemów dla danej kampanii
 *
 * @param {*} { campaignId, lineitemId }
 * @param {*} thunkApi
 * @return {*} lineitem
 */
const handleFetchAllLineitemsForCampaign = async ({ campaignId }) => {
  const response = await adserver({ url: `/campaign/${campaignId}/lineitems` });
  const lineitems = await Promise.all(
    response.data.map(async lineitem => {
      const lineitemId = lineitem.id;
      const prices = await handleFetchPrices({ campaignId, lineitemId });
      const budget = isBudgetVisible()
        ? await handleFetchLineitemBuget({ campaignId, lineitemId })
        : [];
      return { ...lineitem, prices, ...normalizeBudget(budget) };
    }),
  );
  return lineitems.map(lineitem =>
    normalizeApiReponse({ ...lineitem, campaignId }),
  );
};

/**
 * wspólny handler dla akcji pobierających dane budżetu dla lineitemu
 *
 * @param {*} { campaignId, lineitemId }
 * @param {*} thunkApi
 * @return {*} lineitem
 */

const handleFetchLineitemBuget = async ({ campaignId, lineitemId }) => {
  try {
    const { data: budget } = await adserver({
      url: `/campaign/${campaignId}/lineitem/${lineitemId}/budget`,
    });
    return budget;
  } catch (error) {
    return [];
  }
};

export const handleFetchPrices = async ({ campaignId, lineitemId }) => {
  // zapytanie jest w bloku try catch, ponieważ endpoint zwraca błąd 404, jeśli nie ma jeszcze cen dla nowo utworzonego lineitema
  // ma to zostać zmienione na 203 w przyszłości
  try {
    const response = await adserver({
      url: `/campaign/${campaignId}/lineitem/${lineitemId}/actual_prices`,
    });
    return response.data.prices;
  } catch (error) {
    return [];
  }
};

export const fetchAllLineitemsForCampaign = createAsyncThunk(
  NAME + '/fetchAllLineitemsForCampaign',
  withErrorHandling(handleFetchAllLineitemsForCampaign),
);

export const fetchAllLineitemsForCurrentCampaign = createAsyncThunk(
  NAME + '/fetchAllLineitemsForCurrentCampaign',
  withErrorHandling(async (_, thunkApi) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    return await handleFetchAllLineitemsForCampaign({ campaignId });
  }),
);

export const fetchById = createAsyncThunk(
  NAME + '/fetchById',
  withErrorHandling(handleFetchLineitemForCampaign),
);

export const create = createAsyncThunk(
  NAME + '/create',
  withErrorHandling(async (values, thunkAPI) => {
    const { campaignId } = values;
    const response = await adserver({
      url: `/campaign/${campaignId}/lineitem`,
      method: 'POST',
      data: normalizeApiRequest(values),
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
    thunkAPI.dispatch(fetchCampaignBudget({ campaignId }));
    return normalizeApiReponse(response.data);
  }),
);

export const update = createAsyncThunk(
  NAME + '/update',
  withErrorHandling(async (values, thunkAPI) => {
    const { campaignId, id: lineitemId } = values;
    const state = thunkAPI.getState();
    const lineitem = selectById(state, lineitemId);

    const response = await adserver({
      url: `/campaign/${campaignId}/lineitem/${lineitemId}`,
      method: 'PATCH',
      data: normalizeApiRequest(
        mergeWith({}, lineitem, values, (lineitem, value) => {
          // merge łączy wartości kluczy; w przypadku tablic skutkuje to połączeniem wartości
          // dla formularzy edycji dawało to efekt w postaci braku możliwości usuwania elementów z tablicy
          // mergeWith z wyjątkiem dla tablic eliminuje to zachowanie: nowa tablica zawsze nadpisze starą
          if (isArray(value)) {
            return value;
          }
        }),
      ),
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
    thunkAPI.dispatch(fetchTotalStatsForAppsInCurrentCampaign(campaignId));
    thunkAPI.dispatch(fetchTotalStatsForWebsitesInCurrentCampaign(campaignId));
    thunkAPI.dispatch(fetchBudget({ campaignId, lineitemId }));
    thunkAPI.dispatch(fetchCampaignBudget({ campaignId }));
    return normalizeApiReponse(response.data);
  }),
);

export const remove = createAsyncThunk(
  NAME + '/remove',
  withErrorHandling(async ({ campaignId, lineitemId }) => {
    await adserver({
      url: `/campaign/${campaignId}/lineitem/${lineitemId}`,
      method: 'DELETE',
    });
    return lineitemId;
  }),
);

export const clone = createAsyncThunk(
  NAME + '/clone',
  withErrorHandling(async ({ campaignId, lineitemId }, thunkApi) => {
    const state = thunkApi.getState();
    const lineitem = selectById(state, lineitemId);
    const campaign = selectCampaignById(state, campaignId);
    const lineitemEndDate = isBefore(
      lineitem.general.timing.endDate,
      startOfToday(),
    )
      ? campaign.endDate
      : lineitem.general.timing.endDate;

    const clonedLineitemObj = {
      campaignId,
      ...lineitem,
      general: {
        ...lineitem.general,
        name: `${
          lineitem.general.name
        } (cloned at ${new Date().toLocaleString()})`,
        timing: {
          ...lineitem.general.timing,
          endDate: lineitemEndDate,
        },
      },
    };
    return await thunkApi.dispatch(create(clonedLineitemObj));
  }),
);

export const setCurrent = createAsyncThunk(
  NAME + '/current',
  withErrorHandling(async (lineitemId, thunkApi) => {
    if (!lineitemId) return;
    const campaignId = selectCurrentCampaignId(thunkApi.getState());

    // wszystkie zasoby niezbędne do obsługi stron lineitema
    const [lineitem, prices] = await Promise.all([
      handleFetchLineitemForCampaign({ campaignId, lineitemId }),
      handleFetchPrices({ campaignId, lineitemId }),
      thunkApi.dispatch(fetchAllCreatives({ campaignId })),
    ]);

    return { ...lineitem, prices, campaignId };
  }),
);

export const removeAllLineitems = () => ({ type: NAME + '/removeAll' });

export const toggleStatus = createAsyncThunk(
  NAME + '/toggleStatus',
  withErrorHandling(async (lineitemId, thunkAPI) => {
    const lineitem = selectById(thunkAPI.getState(), lineitemId);
    const isActive = !lineitem.status.isActive;
    await adserver({
      url: `/campaign/${lineitem.campaignId}/lineitem/${lineitemId}/status`,
      method: 'POST',
      data: {
        isActive,
      },
    });
    return {
      ...lineitem,
      status: {
        isActive,
      },
    };
  }),
);

export const fetchLineitemFormStatisticsForecast = createAsyncThunk(
  NAME + '/fetchFormStatisticsForecast',
  withErrorHandling(async ({ campaignId, formData, ...axiosProperties }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/lineitem/forecast`,
      method: 'POST',
      data: normalizeApiRequest(formData),
      timeout: 10000,
      ...axiosProperties,
    });
    return response;
  }),
);

export const excludePlacementsForCurrentLineitem = createAsyncThunk(
  NAME + '/excludePlacementsForCurrentLineitem',
  withErrorHandling(async ({ placementType, placementList }, thunkAPI) => {
    const campaignId = selectCurrentCampaignId(thunkAPI.getState());
    const lineitemId = selectCurrentLineitemId(thunkAPI.getState());
    await adserver({
      url: `campaign/${campaignId}/lineitem/${lineitemId}/exclude_placements`,
      method: 'PATCH',
      data: { placementType, placementList },
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
  }),
);

export const includePlacementsForCurrentLineitem = createAsyncThunk(
  NAME + '/includePlacementsForCurrentLineitem',
  withErrorHandling(async ({ placementType, placementList }, thunkAPI) => {
    const campaignId = selectCurrentCampaignId(thunkAPI.getState());
    const lineitemId = selectCurrentLineitemId(thunkAPI.getState());
    await adserver({
      url: `campaign/${campaignId}/lineitem/${lineitemId}/exclude_placements`,
      method: 'PATCH',
      data: { placementType, placementList, undo: true },
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
  }),
);

export const fetchBudget = createAsyncThunk(
  NAME + '/budget/fetch',
  withErrorHandling(async ({ campaignId, lineitemId }) => {
    const { data } = await adserver({
      url: `/campaign/${campaignId}/lineitem/${lineitemId}/budget`,
    });
    return {
      id: lineitemId,
      changes: normalizeBudget(data),
    };
  }),
);

export const updateBudget = createAsyncThunk(
  NAME + '/budget/update',
  withErrorHandling(async ({ entitiyId, budgetObj }, thunkAPI) => {
    const lineitem = selectById(thunkAPI.getState(), entitiyId);
    const campaignId = lineitem.campaignId;
    const { data } = await adserver({
      url: `/campaign/${campaignId}/lineitem/${entitiyId}/budget`,
      method: 'PATCH',
      data: budgetObj,
    });
    thunkAPI.dispatch(fetchCampaignBudget({ campaignId }));
    return {
      id: entitiyId,
      changes: normalizeBudget(data),
    };
  }),
);
