import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { ModalForm, PermissionsGate } from 'components';
import {
  CreateCampaignForm,
  EditCampaignForm,
  campaignCreatePage,
  campaignEditPage,
} from 'components/Campaign';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { withCurrentCampaignId } from 'hoc';
import { CAN_EDIT_CAMPAIGNS, CAN_PREVIEW_CAMPAIGNS } from 'consts/permissions';
import { CampaignId } from './Campaign.types';
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { userHasRequiredPermissions } from 'hooks';

export const CreateCampaignModalForm = ({
  ...props
}: ButtonProps): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <ModalForm
      trigger={
        <Button
          as={Link}
          to={campaignCreatePage()}
          {...props}
          leftIcon={<FiPlus />}
        >
          Create Campaign
        </Button>
      }
      form={<CreateCampaignForm />}
    />
  </PermissionsGate>
);

export const EditCampaignModalForm = ({
  campaignId,
  ...props
}: CampaignId & ButtonProps): JSX.Element => {
  const canEdit = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  const canPreview = userHasRequiredPermissions([CAN_PREVIEW_CAMPAIGNS]);
  const icon = canEdit ? <FiEdit /> : <FiEye />;
  const text = canEdit ? 'Edit' : 'Preview';
  const color = canEdit ? 'primary' : 'solid';

  if (canEdit || canPreview) {
    return (
      <ModalForm
        trigger={
          <Button
            leftIcon={icon}
            variant={color}
            {...props}
            as={Link}
            to={campaignEditPage(campaignId)}
          >
            {text}
          </Button>
        }
        form={<EditCampaignForm campaignId={campaignId} />}
      />
    );
  }
  return <></>;
};

export const EditCurrentCampaignModalForm = withCurrentCampaignId(
  EditCampaignModalForm,
);
