import React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';

const LineitemTargetingCountry = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const countries = useAppSelector(state => state.consts.countries);

  const options = React.useMemo(() => {
    return countries?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [countries]);

  return (
    <ControlledSelectMultiple
      name='targeting.country'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

LineitemTargetingCountry.displayName = 'Lineitem.targeting.country';

export { LineitemTargetingCountry };
