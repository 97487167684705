import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { ModalForm, PermissionsGate } from 'components';
import { withCurrentLineitemId } from 'hoc';
import { CAN_EDIT_CAMPAIGNS, CAN_PREVIEW_CAMPAIGNS } from 'consts/permissions';
import { LineitemIdProps } from './Lineitem.types';
import {
  CreateLineitemForCurrentCampaignForm,
  CreateVideoLineitemForCurrentCampaignForm,
  EditLineitemForm,
  EditVideoLineitemForm,
} from './forms';
import { CreateLineitemLink, EditLineitemLink } from './Lineitem.Links';
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import userHasRequiredPermissions from 'hooks/useUserPermissions';

export const CreateLineitemModalForm = ({
  campaignId,
  ...props
}: ButtonProps & { campaignId: number }): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <ModalForm
      trigger={
        <CreateLineitemLink
          {...props}
          variant='solid'
          leftIcon={<FiPlus />}
          campaignId={campaignId}
        >
          Create Lineitem
        </CreateLineitemLink>
      }
      form={<CreateLineitemForCurrentCampaignForm />}
    />
  </PermissionsGate>
);

export const CreateVideoLineitemModalForm = ({
  campaignId,
  ...props
}: ButtonProps & { campaignId: number }): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <ModalForm
      trigger={
        <CreateLineitemLink
          {...props}
          variant='solid'
          leftIcon={<FiPlus />}
          campaignId={campaignId}
        >
          Create Video Lineitem
        </CreateLineitemLink>
      }
      form={<CreateVideoLineitemForCurrentCampaignForm />}
    />
  </PermissionsGate>
);

export const EditLineitemModalForm = ({
  lineitemId,
  ...props
}: LineitemIdProps & ButtonProps): JSX.Element => {
  const canEdit = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  const canPreview = userHasRequiredPermissions([CAN_PREVIEW_CAMPAIGNS]);
  const icon = canEdit ? <FiEdit /> : <FiEye />;
  const text = canEdit ? 'Edit' : 'Preview';

  if (canEdit || canPreview) {
    return (
      <ModalForm
        trigger={
          <EditLineitemLink
            {...props}
            variant='solid'
            leftIcon={icon}
            lineitemId={lineitemId}
          >
            {text}
          </EditLineitemLink>
        }
        form={<EditLineitemForm lineitemId={lineitemId} />}
      />
    );
  }
  return <></>;
};

export const EditCurrentLineitemModalForm = withCurrentLineitemId(
  EditLineitemModalForm,
);

export const EditVideoLineitemModalForm = ({
  lineitemId,
  ...props
}: LineitemIdProps & ButtonProps): JSX.Element => {
  const canEdit = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  const canPreview = userHasRequiredPermissions([CAN_PREVIEW_CAMPAIGNS]);
  const icon = canEdit ? <FiEdit /> : <FiEye />;
  const text = canEdit ? 'Edit' : 'Preview';

  if (canEdit || canPreview) {
    return (
      <ModalForm
        trigger={
          <EditLineitemLink
            {...props}
            variant='solid'
            leftIcon={icon}
            lineitemId={lineitemId}
          >
            {text}
          </EditLineitemLink>
        }
        form={<EditVideoLineitemForm lineitemId={lineitemId} />}
      />
    );
  }
  return <></>;
};

export const EditCurrentVideoLineitemModalForm = withCurrentLineitemId(
  EditVideoLineitemModalForm,
);
