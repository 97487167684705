import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './advertisers.adapter';
import { REDUCER_KEY } from './advertisers.consts';
import { AdvertiserParams } from './advertisers.types';
import {
  selectAllCampaigns,
  selectById as selectCampaignById,
} from 'store/campaigns';
import { userSelector } from 'store/user';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): AdvertiserParams | null => selectors.selectById(state, id) || null;

export const selectCurrentAdvertiserId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllAdvertisers = createSelector(
  state => selectors.selectAll(state),
  advertisers => advertisers || [],
);

export const selectCurrentAdvertiser = createSelector(
  selectCurrentAdvertiserId,
  selectAllAdvertisers,
  (currentId: number, advertisers: AdvertiserParams[]) =>
    advertisers.find(({ id }: { id: number }) => id === currentId),
);

export const selectAdvertiserAsOptions = createSelector(
  selectAllAdvertisers,
  (allAdvertisers: AdvertiserParams[]) =>
    allAdvertisers.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export const selectAdvertiserAsOptionsOfUser = (
  state: RootState,
): { id: number; name: string }[] => {
  const allAdvertisers = selectAll(state);
  const user = userSelector(state);

  const advertisersOfCampaign = allAdvertisers?.filter(
    advertiser => advertiser?.agency?.id === user?.buyer?.id,
  );
  return advertisersOfCampaign.map(
    ({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }),
  );
};

export const selectAdvertiserAsOptionsOfCampaign = (
  state: RootState,
  campaignId: number,
): { id: number; name: string }[] => {
  const allAdvertisers = selectAll(state);
  const campaign = selectCampaignById(state, campaignId);

  const advertisersOfCampaign = allAdvertisers?.filter(
    advertiser => advertiser?.agency?.id === campaign?.agency?.id,
  );
  return advertisersOfCampaign.map(
    ({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }),
  );
};

export function selectAdvertiser(
  state: RootState,
  advertiserId: number,
): AdvertiserParams | undefined {
  return selectors.selectById(state, advertiserId) || undefined;
}

export const selectCampaignsOfCurrentAdvertiser = createSelector(
  selectCurrentAdvertiserId,
  selectAllCampaigns,
  (currentAdvertiserId, allCampaigns) =>
    allCampaigns.filter(
      campaign => campaign.advertiser === currentAdvertiserId,
    ) || [],
);
