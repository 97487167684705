import { createEntityAdapter } from '@reduxjs/toolkit';
import { IDLE, NO_ERRORS } from 'consts/xhr';

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

export const initialState = adapter.getInitialState({
  status: IDLE,
  errors: NO_ERRORS,
  uploadProgress: 0,
});

export default adapter;
