import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectAll } from 'store/optimizations';
import { useController, useFormContext } from 'react-hook-form';

const LineitemOptimizationsId = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const { control } = useFormContext();
  const {
    field: { value: selectedEventType },
  } = useController({
    name: 'general.eventPlan.eventType',
    control,
  });

  const allOptimizations = useAppSelector(selectAll);

  const options = React.useMemo(() => {
    return allOptimizations
      ?.filter(optimization => optimization.eventType === selectedEventType)
      .map(
        ({
          id,
          optimizationName,
        }: {
          id: string;
          optimizationName: string;
        }) => ({
          id,
          name: optimizationName,
        }),
      );
  }, [allOptimizations, selectedEventType]);

  return (
    <ControlledSelect
      name='optimizationId'
      label='Optimization'
      options={options}
      {...props}
    />
  );
};

LineitemOptimizationsId.displayName = 'Lineitem.optimizationId';

export { LineitemOptimizationsId };
