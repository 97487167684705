import {
  ALL_PERMISSIONS,
  CAN_OVERRIDE_BUDGET_VISIBILITY,
} from 'consts/permissions';
import { createSelector } from 'reselect';
import { NAME } from './user.consts';

export const userSelector = state => state[NAME];
export const selectDefaultCampaignMargin = state =>
  state.user.campaignMargin.defaultValue;

export const selectUserPermissions = createSelector(
  state => state[NAME],
  user => ALL_PERMISSIONS.filter(permission => user[permission] === true) || [],
);

export const isUserLoggedIn = createSelector(
  state => state[NAME],
  user => user.isLoggedIn === true,
);

export const selectUserBuyer = createSelector(
  state => state[NAME],
  user => user.buyer,
);

export const isBudgetVisiblity = createSelector(
  state => state[NAME],
  user => user.buyer.budget_visibility,
);

export const isMarginEnabled = createSelector(
  state => state[NAME],
  user => user.campaignMargin.enabled,
);

export const hasBudgetVisibilityOverride = createSelector(
  state => state[NAME],
  user => user[CAN_OVERRIDE_BUDGET_VISIBILITY],
);

export const isBudgetVisible = createSelector(
  isBudgetVisiblity,
  hasBudgetVisibilityOverride,
  (budgetVisible, budgetOverride) => budgetVisible || budgetOverride,
);
