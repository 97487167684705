import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react';
import {
  Abbr,
  ControlledCheckboxBoolean,
  ControlledInputNumber,
  PermissionsGate,
  TextOverflow,
} from 'components';
import { CheckboxBoolean } from 'components/Checkbox';
import { ShowCreativeLink } from 'components/Creative';
import {
  CAN_EDIT_CAMPAIGNS,
  CAN_VIEW_DYNAMIC_PRICES,
} from 'consts/permissions';
import { useAppSelector } from 'hooks';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import * as React from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { RootState } from 'store';
import { selectCreative } from 'store/creatives';
import { selectLineitem } from 'store/lineitems';
import {
  NAMES,
  SHORT_NAMES,
  STAT_DCPC,
  STAT_DCPM,
  STAT_MCPC,
  STAT_MCPM,
  STAT_DCPV,
  STAT_MCPV,
} from 'store/statistics';
import { isBudgetVisible } from 'store/user';
import { LineitemInterface } from '../Lineitem.schema';

interface CreativeProps {
  creativeId: number;
  name: string;
  isDisabled: boolean;
  isSelected: boolean;
  index: number;
  price: { priceCPC: string; priceCPM: string; priceCPV: string };
  eventType: string;
}

const Creative = ({
  creativeId,
  index,
  isDisabled,
  isSelected,
  price,
  eventType,
}: CreativeProps): JSX.Element => {
  const { colorMode } = useColorMode();
  const selectedColor = colorMode === 'light' ? 'white.100' : 'dark.100';
  const { priceCPC = '-', priceCPM = '-', priceCPV = '-' } = price;
  const creativeData = useAppSelector(state =>
    selectCreative(state, creativeId),
  );
  const creativeFormats = useAppSelector(state => state.consts.creativeFormats);
  const creativeVideoFormats = useAppSelector(
    state => state.consts.videoCreativeFormats,
  );
  const budgetVisible = useAppSelector(isBudgetVisible);

  const formatName = React.useMemo(() => {
    if (!creativeFormats) return;
    if (!creativeVideoFormats) return;
    if (!creativeData) return;
    let formatData;
    if (creativeData.templateId === 3) {
      formatData = creativeVideoFormats.find(
        (format: any) => format.id === creativeData.format,
      );
    } else {
      formatData = creativeFormats.find(
        (format: any) => format.id === creativeData.format,
      );
    }
    if (!formatData) return;
    return formatData.name;
  }, [creativeFormats, creativeData]);

  return (
    <Tr
      key={creativeId}
      backgroundColor={isSelected ? selectedColor : 'transparent'}
    >
      <Td>
        <ShowCreativeLink
          creativeId={creativeId}
          variant='link'
          rightIcon={<FiExternalLink />}
          target='_blank'
        >
          <TextOverflow maxWidth='16ch'>{creativeData?.name}</TextOverflow>
        </ShowCreativeLink>
      </Td>
      <Td>{formatName}</Td>
      {eventType === 'impressions' && (
        <>
          <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
            <Td isNumeric>
              <TextOverflow maxWidth='5ch'>{priceCPM}</TextOverflow>
            </Td>
          </PermissionsGate>
          {budgetVisible && (
            <Td isNumeric>
              <ControlledInputNumber
                name={`creatives.${index}.cpm`}
                width='11ch'
                size='sm'
                precision={2}
                step={0.01}
                min={0.01}
                defaultValue={1}
                addon='$'
                isDisabled={isDisabled}
              />
            </Td>
          )}
        </>
      )}
      {eventType === 'clicks' && (
        <>
          <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
            <Td isNumeric>
              <TextOverflow maxWidth='5ch'>{priceCPC}</TextOverflow>
            </Td>
          </PermissionsGate>
          {budgetVisible && (
            <Td isNumeric>
              <ControlledInputNumber
                name={`creatives.${index}.cpc`}
                width='11ch'
                size='sm'
                precision={2}
                step={0.01}
                min={0.01}
                defaultValue={1}
                addon='$'
                isDisabled={isDisabled}
              />
            </Td>
          )}
        </>
      )}
      {eventType === 'videosc' && (
        <>
          <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
            <Td isNumeric>
              <TextOverflow maxWidth='5ch'>{priceCPV}</TextOverflow>
            </Td>
          </PermissionsGate>
          {budgetVisible && (
            <Td isNumeric>
              <ControlledInputNumber
                name={`creatives.${index}.cpv`}
                width='11ch'
                size='sm'
                precision={2}
                step={0.01}
                min={0.01}
                defaultValue={1}
                addon='$'
                isDisabled={isDisabled}
              />
            </Td>
          )}
        </>
      )}
      <Td isNumeric>
        <ControlledCheckboxBoolean
          width='14ch'
          name={`creatives.${index}.isSelected`}
          checkedLabel='Is Selected'
          uncheckedLabel='Not Selected'
          isChecked={isSelected}
        />
      </Td>
    </Tr>
  );
};

const CreativesList = (): JSX.Element => {
  const { control, watch, getValues } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fields } = useFieldArray({
    name: 'creatives',
    control,
  });
  const creatives = watch('creatives');

  const {
    field: { value: selectedEventType },
  } = useController({
    name: 'general.eventPlan.eventType',
    control,
  });

  const [selectedAll, setSelectedAll] = React.useState(true);

  const canEditCampaigns = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);

  const lineitemId = getValues('id');
  const lineitemSelector = (state: RootState): LineitemInterface =>
    selectLineitem(state, lineitemId);
  const currentLineitem = useAppSelector(lineitemSelector);
  const { prices = [] } = currentLineitem || {};

  const handleSelectAll = (): void => {
    creatives.forEach((creative: any) => {
      creative.isSelected = !selectedAll;
    });
    setSelectedAll(!selectedAll);
  };

  const budgetVisible = useAppSelector(isBudgetVisible);

  React.useEffect(() => {
    const subsciption = watch(({ creatives }: { creatives: any }) => {
      if (creatives.every((creative: any) => creative.isSelected))
        setSelectedAll(true);
      if (creatives.every((creative: any) => creative.isSelected === false))
        setSelectedAll(false);
    });
    return () => subsciption.unsubscribe();
  }, [watch]);

  return (
    <Table variant='simple'>
      <Thead>
        <Tr>
          <Th>name</Th>
          <Th>format</Th>
          {selectedEventType === 'impressions' && (
            <>
              <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_DCPM]}
                    tooltip={NAMES[STAT_DCPM]}
                  />
                </Th>
              </PermissionsGate>
              {budgetVisible && (
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_MCPM]}
                    tooltip={NAMES[STAT_MCPM]}
                  />
                </Th>
              )}
            </>
          )}
          {selectedEventType === 'clicks' && (
            <>
              <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_DCPC]}
                    tooltip={NAMES[STAT_DCPC]}
                  />
                </Th>
              </PermissionsGate>
              {budgetVisible && (
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_MCPC]}
                    tooltip={NAMES[STAT_MCPC]}
                  />
                </Th>
              )}
            </>
          )}
          {selectedEventType === 'videosc' && (
            <>
              <PermissionsGate requiredPermissions={[CAN_VIEW_DYNAMIC_PRICES]}>
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_DCPV]}
                    tooltip={NAMES[STAT_DCPV]}
                  />
                </Th>
              </PermissionsGate>
              {budgetVisible && (
                <Th isNumeric>
                  <Abbr
                    text={SHORT_NAMES[STAT_MCPV]}
                    tooltip={NAMES[STAT_MCPV]}
                  />
                </Th>
              )}
            </>
          )}
          <Th isNumeric>
            <CheckboxBoolean
              width='18ch'
              name='select-all'
              onChange={handleSelectAll}
              isChecked={selectedAll}
              checkedLabel='Deselect All'
              uncheckedLabel='Select All'
            />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {creatives?.map((creative: any, index: number) => (
          <Creative
            {...creative}
            key={creative.creativeId}
            index={index}
            price={
              prices.find((price: any) => price.id === creative.creativeId) ||
              {}
            }
            isSelected={creative?.isSelected}
            isDisabled={!canEditCampaigns}
            eventType={selectedEventType}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export { CreativesList };
