import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Input, Alert } from '@chakra-ui/react';

const DEFAULT_MIN_STR_LENGTH = 3;

const withFilteringInput = (Component, resultPropName) => {
  const FilteringInput = ({
    array,
    filteredKey,
    showUnfilteredResults,
    minStringLengthRequired,
    size,
    ...props
  }) => {
    const data = array;
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleInputChange = e => setQuery(e.target.value.toLowerCase());

    useEffect(() => {
      let queryResults = [];
      if (query.length >= minStringLengthRequired) {
        queryResults = data.filter(({ [filteredKey]: key }) =>
          key?.toLowerCase().includes(query),
        );
      }

      setResults(queryResults);
    }, [query, data]);

    return (
      <Box>
        <Input
          size={size}
          onChange={handleInputChange}
          placeholder={`Type at least ${minStringLengthRequired} characters`}
        />
        <Box mt={2}>
          {results.length || showUnfilteredResults ? (
            <Component
              {...props}
              {...{ [resultPropName]: results.length ? results : data }}
            />
          ) : (
            query.length >= minStringLengthRequired && (
              <Alert status='info'>No results found</Alert>
            )
          )}
        </Box>
      </Box>
    );
  };
  FilteringInput.propTypes = {
    size: PropTypes.string,
    array: PropTypes.array,
    filteredKey: PropTypes.string.isRequired,
    showUnfilteredResults: PropTypes.bool.isRequired,
    minStringLengthRequired: PropTypes.number.isRequired,
  };
  FilteringInput.defaultProps = {
    array: [],
    size: 'sm',
    showUnfilteredResults: false,
    minStringLengthRequired: DEFAULT_MIN_STR_LENGTH,
  };

  return FilteringInput;
};

withFilteringInput.propTypes = {
  Component: PropTypes.element.isRequired,
  resultPropName: PropTypes.string.isRequired,
};

export default withFilteringInput;
