import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { XHR_IDLE, XHR_PENDING, XHR_FULFILLED, XHR_REJECTED } from 'consts/xhr';
import { ErrorPage } from 'pages/ErrorPage';
import { EntityPageSkeletor } from 'components/Skeletons';
import { CampaignInterface } from 'components/Campaign';
import { LineitemInterface } from 'components/Lineitem';

type Entity = CampaignInterface | LineitemInterface;

type EntityGateProps = {
  selector: (state: RootState) => Entity;
  fetchAction: AsyncThunk<any, any, any>;
  children(entity: Entity): React.ReactElement;
};

type XHR_STATUS =
  | typeof XHR_IDLE
  | typeof XHR_PENDING
  | typeof XHR_FULFILLED
  | typeof XHR_REJECTED;

/**
 * Bramka, ładująca dany zasób i prezentująca odpowiedni komunikat userowi w przypadku komplikacji
 */
export const EntityGate = ({
  selector,
  fetchAction,
  children,
}: EntityGateProps): JSX.Element => {
  const entity = useAppSelector(selector);
  const dispatch = useDispatch();
  const [fetchState, setFetchState] = React.useState<{
    status: XHR_STATUS;
    code?: number;
    message?: string;
  }>({ status: XHR_IDLE });

  async function fetchEntityData() {
    setFetchState({ status: XHR_PENDING });
    try {
      const response = await dispatch(fetchAction({}));
      unwrapResult(response);
      setFetchState({ status: XHR_FULFILLED });
    } catch (error: any) {
      const message = error?.data?.message || 'Something went wrong';
      setFetchState({
        status: XHR_REJECTED,
        code: error.status,
        message,
      });
    }
  }

  React.useEffect(() => {
    if (fetchState.status === XHR_PENDING) return;
    if (fetchState.status === XHR_REJECTED) return;
    if (!selector || !fetchAction) return;
    if (!entity) fetchEntityData();
  }, [selector, fetchAction, fetchState.status]);

  if (entity) return <>{children(entity)}</>;

  if (fetchState.status === XHR_PENDING) return <EntityPageSkeletor />;
  if (fetchState.status === XHR_FULFILLED) return <>{'???'}</>;
  if (fetchState.status === XHR_REJECTED)
    return <ErrorPage status={fetchState.code} message={fetchState.message} />;

  return <></>;
};
