import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './optimizations.consts';

function normalizeApiReponse(data) {
  return data;
}

function normalizeApiRequest(data) {
  return data;
}

export const fetchAllOptimizations = createAsyncThunk(
  NAME + '/list',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/optimizations' });
    return normalizeApiReponse(response.data);
  }),
);

export const fetchById = createAsyncThunk(
  NAME + '/get',
  withErrorHandling(async ({ optimizationId }) => {
    const response = await adserver({ url: `/optimization/${optimizationId}` });
    return normalizeApiReponse(response.data);
  }),
);

export const update = createAsyncThunk(
  NAME + '/update',
  withErrorHandling(async ({ optimizationId, optimizationObj }) => {
    const response = await adserver({
      url: `/optimization/${optimizationId}`,
      method: 'PATCH',
      data: normalizeApiRequest(optimizationObj),
    });
    return normalizeApiReponse(response.data);
  }),
);

export const setCurrentOptimization = createAsyncThunk(
  NAME + '/current',
  withErrorHandling(async optimizationId => {
    return optimizationId;
  }),
);

export const removeAllOptimizations = () => ({ type: NAME + '/removeAll' });
