import React from 'react';
import { ControlledCheckboxGroup } from 'components';
import { useController, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';

const LineitemTargetingOperatingSystem = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  /**
   * lista wszystkich systemów operacyjnych
   */
  const operatingSystems = useAppSelector(
    state => state.consts.operatingSystems,
  );
  /**
   * lista traffic sources; każdy traffic source zawiera listę id systemów operacyjnych, które obsługuje
   */
  const allTrafficSources = useAppSelector(state => state.consts.trafficSource);
  /**
   * zawartość pola trafficSource w formularzu: lista kluczy wybranych trafficSource,
   * na bazie której generujemy listę dozwolonych do wybrania systemów operacyjnych
   */
  const { control } = useFormContext();
  const {
    field: { value: selectedTrafficSources },
  } = useController({
    name: 'targeting.trafficSource',
    control,
  });

  const userCanEditField = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  /**
   * lista dozwolonych systemów operacyjnych jest generowana na podstawie bieżąco wybranych trafficSource
   * każdy trafficSource w consts zawiera listę przypisanych do niego kluczy OS
   * pobieramy listę bieżąco wybranych traffic sources
   * generujemy unikalny set z kluczami dozwolonych os
   * generujemy na bazie setu tablicę z opcjami
   *
   */
  const allowedOperatingSystems = React.useMemo(() => {
    if (!allTrafficSources) return operatingSystems;
    /**
     * unikalna lista kluczy systemów operacyjnych, wygenerowana na bazie bieżąco wybranych trafficSource
     */
    const allowedOperatingSystemsKeys = [
      ...new Set(
        selectedTrafficSources?.flatMap((trafficSourceId: any) => {
          const trafficSource = allTrafficSources.find(
            ({ id }: { id: string }) => id === trafficSourceId,
          );
          return trafficSource.operatingSystems;
        }),
      ),
    ];

    return operatingSystems?.map((system: any) => {
      const isAllowed = allowedOperatingSystemsKeys.includes(system.id);
      if (!userCanEditField) {
        return {
          ...system,
          isDisabled: true,
        };
      }
      return {
        ...system,
        isDisabled: !isAllowed,
      };
    });
  }, [
    operatingSystems,
    allTrafficSources,
    selectedTrafficSources,
    userCanEditField,
  ]);

  return (
    <ControlledCheckboxGroup
      name='targeting.operatingSystem'
      label='Operating system'
      values={allowedOperatingSystems}
      w='100%'
      {...props}
    />
  );
};

LineitemTargetingOperatingSystem.displayName =
  'Lineitem.targeting.operatingSystem';

export { LineitemTargetingOperatingSystem };
