/* eslint-disable no-unused-vars */
import { createSelector } from 'reselect';
import { pick } from 'lodash';
import { selectCurrentDealId, selectDealsOfCurrentCampaign } from 'store/deals';
import { selectCurrentCampaignId } from 'store/campaigns';
import {
  ENTITY_CAMPAIGN,
  ENTITY_DEAL,
  TIMEUNIT_TOTAL,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  selectAllDeals,
  selectAllWebsites,
  selectAllOperatingsystems,
  selectAllCities,
  selectAllCoordinates,
  selectAllApps,
  selectAllExchanges,
  selectAllCreatives,
} from 'store/statistics';

export const selectTotalStatsForAllDeals = createSelector(
  selectAllDeals,
  allDeals => allDeals.filter(deal => deal.timeunit === TIMEUNIT_TOTAL),
);

export const selectMonthlyStatsForAllDeals = createSelector(
  selectAllDeals,
  allDeals => allDeals.filter(deal => deal.timeunit === TIMEUNIT_MONTH),
);

export const selectWeeklyStatsForAllDeals = createSelector(
  selectAllDeals,
  allDeals => allDeals.filter(deal => deal.timeunit === TIMEUNIT_WEEK),
);

export const selectDailyStatsForAllDeals = createSelector(
  selectAllDeals,
  allDeals => allDeals.filter(deal => deal.timeunit === TIMEUNIT_DAY),
);

export const selectHourlyStatsForAllDeals = createSelector(
  selectAllDeals,
  allDeals => allDeals.filter(deal => deal.timeunit === TIMEUNIT_HOUR),
);

export const selectTotalStatsForCurrentDeal = createSelector(
  selectCurrentDealId,
  selectTotalStatsForAllDeals,
  (selectCurrentDealId, totalStatsForAllDeals) =>
    totalStatsForAllDeals.find(
      deal => deal[ENTITY_DEAL] === selectCurrentDealId,
    ) || {},
);

export const selectMonthlyStatsForCurrentDeal = createSelector(
  selectCurrentDealId,
  selectMonthlyStatsForAllDeals,
  (selectCurrentDealId, monthlyStatsForAllDeals) =>
    monthlyStatsForAllDeals.filter(
      deal => deal[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectWeeklyStatsForCurrentDeal = createSelector(
  selectCurrentDealId,
  selectWeeklyStatsForAllDeals,
  (selectCurrentDealId, weeklyStatsForAllDeals) =>
    weeklyStatsForAllDeals.filter(
      deal => deal[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectDailyStatsForCurrentDeal = createSelector(
  selectCurrentDealId,
  selectDailyStatsForAllDeals,
  (selectCurrentDealId, dailyStatsForAllDeals) =>
    dailyStatsForAllDeals.filter(
      deal => deal[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectLastWeekStatsForCurrentDeal = createSelector(
  selectDailyStatsForCurrentDeal,
  dailyStatsForCurrentDeal => dailyStatsForCurrentDeal.slice(-7) || [],
);

export const selectHourlyStatsForCurrentDeal = createSelector(
  selectCurrentDealId,
  selectHourlyStatsForAllDeals,
  (selectCurrentDealId, hourlyStatsForAllDeals) =>
    hourlyStatsForAllDeals.filter(
      deal => deal[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectTotalStatsForAppsInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllApps,
  (selectCurrentDealId, allApps) =>
    allApps.filter(app => app[ENTITY_DEAL] === selectCurrentDealId) || [],
);

export const selectTotalStatsForWebsitesInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllWebsites,
  (selectCurrentDealId, allWebsites) =>
    allWebsites.filter(
      website => website[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectTotalStatsForOperatingsystemsInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllOperatingsystems,
  (selectCurrentDealId, allOperatingsystems) =>
    allOperatingsystems.filter(os => os[ENTITY_DEAL] === selectCurrentDealId) ||
    [],
);

export const selectTotalStatsForCitiesInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllCities,
  (selectCurrentDealId, allCities) =>
    allCities.filter(city => city[ENTITY_DEAL] === selectCurrentDealId) || [],
);

export const selectTotalStatsForCoordinatesInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllCoordinates,
  (selectCurrentDealId, allCoordinates) =>
    allCoordinates.filter(
      coordinate => coordinate[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectTotalStatsForExchangesInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllExchanges,
  (selectCurrentDealId, allExchanges) =>
    allExchanges.filter(
      exchange => exchange[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectTotalStatsForCreativesInCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllCreatives,
  (selectCurrentDealId, allCreatives) =>
    allCreatives.filter(
      creative => creative[ENTITY_DEAL] === selectCurrentDealId,
    ) || [],
);

export const selectTotalStatsForDealsOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllDeals,
  (currentCampaignId, allDeals) =>
    allDeals.filter(Deal => Deal[ENTITY_CAMPAIGN] === currentCampaignId) || [],
);
