import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  useToast,
} from '@chakra-ui/react';
import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { CampaignInterface } from 'components/Campaign';
import { LineitemInterface } from 'components/Lineitem';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import {
  useAppDispatch,
  useAppSelector,
  userHasRequiredPermissions,
} from 'hooks';
import * as React from 'react';
import { RootState } from 'store';

export type StatusTogglerProps = {
  selector: (state: RootState) => CampaignInterface | LineitemInterface;
  toggleAction: AsyncThunk<any, any, any>;
};

export const StatusToggler = ({
  selector,
  toggleAction,
}: StatusTogglerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const showToast = useToast();
  const [isPending, setIsPending] = React.useState(false);
  const userCanEdit = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  const entity = useAppSelector(selector);

  if (!('status' in entity)) return null;
  const isActive = entity.status.isActive;

  async function handleButtonClick(): Promise<any> {
    setIsPending(true);
    try {
      const response = unwrapResult(await dispatch(toggleAction(entity.id)));
      if (response?.error) {
        throw new Error(response.error);
      }

      showToast({
        title: 'Status has been changed',
        description: `${entity.name} is now ${
          isActive ? 'inactive' : 'active'
        }`,
        position: 'bottom',
        status: isActive ? 'info' : 'success',
        duration: 3000,
      });
    } catch (error) {
      const message = error?.message;
      showToast({
        title: 'Something went wrong',
        description: message || 'Please try again later',
        position: 'bottom',
        status: 'error',
        isClosable: true,
      });
    }
    setIsPending(false);
  }

  return (
    <Alert variant='subtle' status={isActive ? 'success' : 'info'} padding={2}>
      {userCanEdit && (
        <Button
          size='sm'
          minW='12ch'
          marginRight={5}
          colorScheme={isActive ? 'green' : 'blue'}
          isLoading={isPending}
          onClick={handleButtonClick}
        >
          {isActive ? 'Disable' : 'Enable'}
        </Button>
      )}
      <AlertIcon />
      <AlertTitle>{isActive ? 'Is active' : 'Is not active'}</AlertTitle>
    </Alert>
  );
};
