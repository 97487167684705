import { createSlice } from '@reduxjs/toolkit';
import { NAME, initialState } from './user.consts';
import { login, logout, ping } from './user.actions';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [logout.fulfilled]: () => initialState,
    [logout.rejected]: () => initialState,
    [login.rejected]: () => initialState,
    [login.fulfilled]: (state, action) => action.payload,
    [ping.rejected]: () => initialState,
    [ping.fulfilled]: (state, action) => action.payload,
  },
});

export const { reducer } = slice;
