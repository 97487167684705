import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';

const LineitemVideoPlacementTypes = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const placementTypes = useAppSelector(
    state => state.consts.videoPlacementTypes,
  );

  const options = React.useMemo(() => {
    return placementTypes?.map(
      ({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }),
    );
  }, [placementTypes]);

  return (
    <ControlledSelectMultiple
      name='video.placementTypes'
      label='Placement Type'
      options={options}
      {...props}
    />
  );
};

LineitemVideoPlacementTypes.displayName = 'Lineitem.video.placementTypes';

export { LineitemVideoPlacementTypes };
