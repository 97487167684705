import * as React from 'react';
import { useAppSelector } from 'hooks';
import { selectCurrentCampaignId } from 'store/campaigns';
import { selectCurrentCreativeId } from 'store/creatives';
import { selectCurrentTargetingId } from 'store/targetings';
import { selectCurrentLineitemId } from 'store/lineitems';
import { selectCurrentAdvertiserId } from 'store/advertisers';
import { selectCurrentDealId } from 'store/deals';
import { selectCurrentProposalId } from 'store/proposals';
import { ReactComponent } from 'types';

const SELECTORS: Record<string, any> = {
  campaign: selectCurrentCampaignId,
  creative: selectCurrentCreativeId,
  targeting: selectCurrentTargetingId,
  lineitem: selectCurrentLineitemId,
  advertiser: selectCurrentAdvertiserId,
  deal: selectCurrentDealId,
  proposal: selectCurrentProposalId,
};

export const withCurrentEntityId = (
  Component: React.ElementType,
  entityName:
    | 'campaign'
    | 'creative'
    | 'targeting'
    | 'lineitem'
    | 'advertiser'
    | 'deal'
    | 'proposal',
): React.ElementType => {
  const ComponentWithCurrentEntityId = ({
    children,
    ...props
  }: ReactComponent): JSX.Element | null => {
    const entitySelector = SELECTORS[entityName];
    const entityId = useAppSelector(entitySelector);

    if (!entityId) return null;

    return (
      <Component {...props} {...{ [`${entityName}Id`]: entityId }}>
        {children}
      </Component>
    );
  };
  return ComponentWithCurrentEntityId;
};

export const withCurrentCampaignId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'campaign');

export const withCurrentCreativeId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'creative');

export const withCurrentTargetingId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'targeting');

export const withCurrentLineitemId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'lineitem');

export const withCurrentAdvertiserId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'advertiser');

export const withCurrentDealId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'deal');

export const withCurrentProposalId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'proposal');
