import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';
import store from 'store';

axiosThrottle.use(axios, { requestsPerSecond: 50 });

axios.interceptors.response.use(
  res => res,
  error => {
    if (error?.response?.status === 401) {
      store.dispatch({ type: 'TOKEN_REJECTED' });
    }
    throw error;
  },
);

const adserver = ({ method = 'GET', headers, ...properties }) => {
  return axios({
    baseURL: process.env.REACT_APP_ADSERVER_ENDPOINT,
    method,
    headers: {
      Authorization: `Token ${store.getState().user.token}`,
      ...headers,
    },
    ...properties,
  }).catch(error => {
    // source: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      throw error.response;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      throw error.request;
    } else {
      // Something happened in setting up the request and triggered an Error
      throw error.message;
    }
  });
};

export default adserver;
