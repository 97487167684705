/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Grid,
  Flex,
  Avatar,
  Box,
  Text,
  Code,
  Icon,
  Button,
  Stack,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from 'store/user';
import { FaBell, FaUserAlt } from 'react-icons/fa';
import ColorModeSwitcher from '../ColorModeSwitcher';

const Logout = () => {
  const dispatch = useDispatch();
  const handleClick = e => dispatch(logout());
  return <MenuItem onClick={handleClick}>Logout</MenuItem>;
};

const LoginButton = () => (
  <Button variant='primary' as={Link} to='/login'>
    Login
  </Button>
);

const LoggedUserMenu = ({ name }) => (
  <Stack spacing={10} direction='row' align='center'>
    <Box>
      <Menu placement='bottom'>
        <MenuButton variant='ghost' position='relative' p={1}>
          <Badge
            variant='solid'
            bg='primary'
            position='absolute'
            top={0}
            left='50%'
          >
            23
          </Badge>
          <Icon as={FaBell} fontSize='xl' color='secondary' />
        </MenuButton>
        <MenuList>
          <Box px={3} fontWeight='bold'>
            Notifications
          </Box>
          <MenuDivider />
          <Box px={3} fontSize='sm'>
            No notification ATM
          </Box>
        </MenuList>
      </Menu>
    </Box>
    <Box>
      <Menu>
        <MenuButton variant='ghost' position='relative' p={1} pr={6}>
          <Icon as={FaUserAlt} fontSize='xl' color='secondary' />
        </MenuButton>
        <MenuList>
          <Flex px={3} py={1}>
            <Avatar src='https://cdn1.iconfinder.com/data/icons/shiba-inu/500/Shiba_Inu_Emoticon-12-512.png' />
            <Box ml='3'>
              <Text fontWeight='bold'>Haruki Hauhauka</Text>
              <Text fontSize='sm'>Branch Manager</Text>
            </Box>
          </Flex>
          <MenuDivider />
          <MenuItem>User Management</MenuItem>
          <MenuItem>Manage Profile</MenuItem>
          <MenuDivider />
          <Logout />
        </MenuList>
      </Menu>
    </Box>
  </Stack>
);
LoggedUserMenu.propTypes = {
  name: PropTypes.string,
};

const UserMenu = () => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  return isLoggedIn ? <LoggedUserMenu /> : <LoginButton />;
};

const InstanceInfo = () => {
  const version = useSelector(state => state.consts.version);

  if (!version || version.instance === 'prod') return null;

  return (
    <Alert status='info' p={6}>
      <AlertIcon />
      <Box>
        <AlertTitle>
          You are on non-production <Code>{version.instance}</Code> instance 🛠️
        </AlertTitle>
        <AlertDescription>
          <Flex gridGap={6}>
            <Box>
              api <Code>{version.api}</Code>
            </Box>
            <Box>
              ui <Code>{version.ui}</Code>
            </Box>
          </Flex>
        </AlertDescription>
      </Box>
    </Alert>
  );
};

const Header = ({ gridArea }) => {
  return (
    <Grid gridArea={gridArea} w='100%' gap={2} p={6}>
      <InstanceInfo />
      <Flex direction='row' alignItems='center' flex='1 1 100%'>
        {/* <Input
          disabled
          variant='filled'
          placeholder='Search through campaigns, creatives and lineitems'
          maxWidth='50%'
        /> */}
        <Box mx='auto' />
        <ColorModeSwitcher />
      </Flex>
    </Grid>
  );
};

Header.propTypes = {
  gridArea: PropTypes.string,
};

export default Header;
