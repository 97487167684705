import { createSelector } from 'reselect';
import adapter from './modelsets.adapter';
import { NAME } from './modelsets.consts';

const selectors = adapter.getSelectors(state => state[NAME]);
export const { selectIds, selectEntities, selectTotal, selectById } = selectors;

export const selectAll = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);

export const selectModelsetsAsOptions = createSelector(
  selectAll,
  allModelsets => allModelsets.map(({ id, name }) => ({ id, name })),
);
