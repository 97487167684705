import React from 'react';
import { ControlledRadioGroup } from 'components';

const VALUES = [
  { name: 'Per campaign', id: false },
  { name: 'Per lineitem', id: true },
];

const CampaignBudgetPerLineitem = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  return (
    <ControlledRadioGroup
      name='budgetPerLineitem'
      label='Budget type'
      values={VALUES}
      valueType='bool'
      {...props}
    />
  );
};

CampaignBudgetPerLineitem.displayName = 'Campaign.budgetPerLineitem';

export { CampaignBudgetPerLineitem };
