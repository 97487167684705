import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import adFusionLogo from '../AdFusion_logo.png';

const Logo = ({ ...props }) => {

  return (
    <Box {...props}>
	   <img src={adFusionLogo}/>
    </Box>
  );
};

Logo.propTypes = {
  variant: PropTypes.string,
};

export default Logo;
