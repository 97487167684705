import React from 'react';
import { ControlledCheckboxGroup } from 'components';
import { useAppSelector } from 'hooks';

const LineitemTargetingTrafficSource = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const trafficSource = useAppSelector(state => state.consts.trafficSource);

  const options = React.useMemo(() => {
    return trafficSource?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [trafficSource]);

  return (
    <ControlledCheckboxGroup
      name='targeting.trafficSource'
      label='Traffic source'
      values={options}
      w='100%'
      {...props}
    />
  );
};

LineitemTargetingTrafficSource.displayName = 'Lineitem.targeting.trafficSource';

export { LineitemTargetingTrafficSource };
