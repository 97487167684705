import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

interface ModalFormProps {
  trigger: JSX.Element;
  info: JSX.Element;
}

export const ModalInfo = ({ trigger, info }: ModalFormProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleClick(event: React.MouseEvent): void {
    event.preventDefault();
    onOpen();
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleClick })}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size='5xl'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>{React.cloneElement(info)}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
