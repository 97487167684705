import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';

const LineitemTargetingDemographicsAge = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const age = useAppSelector(state => state.consts.ageDemographics);

  const options = React.useMemo(() => {
    return age?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [age]);

  return (
    <ControlledSelectMultiple
      name='targeting.demographics.age'
      label='Age'
      options={options}
      {...props}
    />
  );
};

LineitemTargetingDemographicsAge.displayName =
  'Lineitem.targeting.demographics.age';

export { LineitemTargetingDemographicsAge };
