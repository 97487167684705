import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Button } from '@chakra-ui/react';

interface Button {
  children?: React.ReactNode;
  [key: string]: any;
}

export const SubmitButton = ({
  children = 'Save changes',
  ...props
}: Button): JSX.Element => {
  const { control } = useFormContext();
  const { isSubmitting } = useFormState({ control });
  return (
    <Button isLoading={isSubmitting} variant='primary' type='submit' {...props}>
      {children}
    </Button>
  );
};

export const ResetButton = ({
  children = 'Reset changes',
  ...props
}: Button): JSX.Element => {
  const { control, reset } = useFormContext();
  const { isSubmitting } = useFormState({ control });
  function handleClick(): void {
    reset();
  }
  return (
    <Button isLoading={isSubmitting} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};
