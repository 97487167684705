import React from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import FieldContainer from './Select.FieldContainer';
import Field from './Select.Field';
import Options from './Select.Options';
import { SELECTFIELD_SHARED_PROPS } from './Select.consts';

/**
 * pole z pojedynczą opcją do wyboru
 */
const SingleOptionSelectField = ({
  options,
  placeholder,
  size,
  variant,
  selectedId,
  isDisabled,
  isCleareable,
  isFilterable,
  showUnfilteredResults,
  onChange,
}) => {
  const handleChange = e => {
    const value = e?.selectedItem?.id;
    onChange(value !== undefined ? value : null);
  };
  const handleClear = () => onChange(null);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    selectedItem: options.find(option => option.id === selectedId) || null,
    onSelectedItemChange: handleChange,
  });

  return (
    <FieldContainer size={size} variant={variant}>
      <Field
        isOpen={isOpen}
        isCleareable={isCleareable}
        onClear={handleClear}
        isDisabled={isDisabled}
        hasSelectedOption={selectedId !== null}
        {...getToggleButtonProps()}
      >
        {selectedItem?.name || placeholder}
      </Field>
      <Options
        isOpen={isOpen}
        options={options.map((item, index) => ({
          ...item,
          ...getItemProps({ item, index }),
          isSelected: item.id === selectedId,
        }))}
        isFilterable={isFilterable}
        showUnfilteredResults={showUnfilteredResults}
        {...getMenuProps()}
      />
    </FieldContainer>
  );
};

SingleOptionSelectField.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...SELECTFIELD_SHARED_PROPS,
};

SingleOptionSelectField.defaultProps = {
  placeholder: 'Select option',
  options: [],
};

export default SingleOptionSelectField;
