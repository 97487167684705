import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Box, Grid, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  MdOutlineHandshake,
  MdCached,
  MdRadar,
  MdOutlinePeopleAlt,
  MdNotes,
  MdOutlineLogout,
  MdOutlineDataset,
} from 'react-icons/md';
import {
  CAMPAIGN_LIST,
  TARGETING_LIST,
  MODELSET_LIST,
  OPTIMIZATION_LIST,
  ADVERTISER_LIST,
  DEAL_LIST,
} from 'routes';
import {
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_MODELSETS,
  CAN_VIEW_TARGETINGS,
  CAN_VIEW_ADVERTISERS,
  CAN_VIEW_FINALIZED_DEALS,
} from 'consts/permissions';
import { logout } from 'store/user';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import Logo from './Logo';

const MenuOption = ({ label, icon, ...props }) => (
  <Grid
    templateAreas={`
    "icon"
    "label"
  `}
    gap={2}
    alignItems='center'
    {...props}
  >
    <Box
      width='fit-content'
      gridArea='icon'
      mx={5}
      px={2}
      bg='grass.200'
      borderRadius='md'
      _hover={{
        bg: 'grass.100',
      }}
    >
      <Icon
        as={icon}
        color='white.100'
        display='block'
        boxSize={5}
        mx={3}
        my={2}
      />
    </Box>
    <Box gridArea='label' fontSize='xs' textAlign='center'>
      {label}
    </Box>
  </Grid>
);
MenuOption.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
};

const Link = ({ label, icon, to, permissions }) => {
  const hasPermissions = userHasRequiredPermissions(permissions);
  return hasPermissions ? (
    <MenuOption label={label} icon={icon} as={RouterLink} to={to} />
  ) : null;
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.any,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const User = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.user);

  const handleClick = () => dispatch(logout());

  if (!isLoggedIn) return null;

  return (
    <>
      <MenuOption label='Logout' icon={MdOutlineLogout} onClick={handleClick} />
    </>
  );
};

const Sidebar = ({ gridArea }) => {
  return (
    <Grid
      gridArea={gridArea}
      py={4}
      gap={6}
      w='min-content'
      height='100%'
      bg='grass.300'
      color='white.100'
      templateRows='1fr min-content'
    >
      <Stack direction='column' spacing={4} marginTop={-4}>
        <RouterLink to={CAMPAIGN_LIST}>
          <Logo mt={8} mb={4} px={4} />
        </RouterLink>
        <Link
          to={CAMPAIGN_LIST}
          permissions={[CAN_VIEW_CAMPAIGNS]}
          label='Campaigns'
          icon={MdNotes}
        />
        <Link
          to={ADVERTISER_LIST}
          permissions={[CAN_VIEW_ADVERTISERS]}
          label='Adverisers'
          icon={MdOutlinePeopleAlt}
        />
        <Link
          to={TARGETING_LIST}
          permissions={[CAN_VIEW_TARGETINGS]}
          label='Targetings'
          icon={MdRadar}
        />
        <Link
          to={MODELSET_LIST}
          permissions={[CAN_VIEW_MODELSETS]}
          label='Modelsets'
          icon={MdOutlineDataset}
        />
        <Link
          to={OPTIMIZATION_LIST}
          permissions={[CAN_VIEW_MODELSETS]}
          label='Optimizations'
          icon={MdCached}
        />
        <Link
          to={DEAL_LIST}
          permissions={[CAN_VIEW_FINALIZED_DEALS]}
          label='Private Deals'
          icon={MdOutlineHandshake}
        />
      </Stack>
      <User />
    </Grid>
  );
};

Sidebar.propTypes = {
  gridArea: PropTypes.string,
};

export default Sidebar;
