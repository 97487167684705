import React from 'react';
import { ControlledCheckboxBoolean } from 'components';

const LineitemTargetingGeoTargetingListEnabled = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledCheckboxBoolean
    name='targeting.geoTargetingList.enabled'
    label='Status'
    checkedLabel='Is enabled'
    uncheckedLabel='Is disabled'
    w='100%'
    {...props}
  />
);

LineitemTargetingGeoTargetingListEnabled.displayName =
  'Lineitem.targeting.geoTargetingList.enabled';

export { LineitemTargetingGeoTargetingListEnabled };
