import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectAllGeoTargetings } from 'store/targetings';

const LineitemTargetingGeoTargetingListTargeting = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const geotargetingAllowedValues = useAppSelector(selectAllGeoTargetings);

  const options = React.useMemo(() => {
    return geotargetingAllowedValues?.map(
      ({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }),
    );
  }, [geotargetingAllowedValues]);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'targeting.geoTargetingList.enabled',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(!isActive);
  }, [isActive]);

  return (
    <ControlledSelect
      name='targeting.geoTargetingList.targeting'
      label='List'
      options={options}
      isDisabled={isDisabled}
      isCleareable
      {...props}
    />
  );
};

LineitemTargetingGeoTargetingListTargeting.displayName =
  'Lineitem.targeting.geoTargetingList.targeting';

export { LineitemTargetingGeoTargetingListTargeting };
