import { useFormContext, useController } from 'react-hook-form';

const BOOLEAN = 'bool';
const ARRAY = 'array';

const useIsSelected = (name, fieldValue) => {
  const { control } = useFormContext();
  const {
    field: { onChange: setFormValue, value: formValue },
  } = useController({ name, control });

  const fieldType = Array.isArray(formValue) ? ARRAY : BOOLEAN;

  const isSelected =
    fieldType === ARRAY
      ? formValue.includes(fieldValue)
      : formValue === fieldValue;

  const handleSelectionChange = () => {
    switch (fieldType) {
      case ARRAY:
        setFormValue(
          isSelected
            ? formValue.filter(element => element !== fieldValue)
            : [...formValue, fieldValue],
        );
        break;
      case BOOLEAN:
        return setFormValue(!isSelected);
      default:
        break;
    }
  };

  return { isSelected, fieldType, handleSelectionChange };
};

export default useIsSelected;
