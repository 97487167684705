import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelectMultiple } from 'components';

const LineitemVideoPlaybackMethod = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const playbackMethod = useAppSelector(
    state => state.consts.videoPlaybackMethodTypes,
  );

  const options = React.useMemo(() => {
    return playbackMethod?.map(
      ({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }),
    );
  }, [playbackMethod]);

  return (
    <ControlledSelectMultiple
      name='video.playbackMethod'
      label='Playback Method'
      options={options}
      {...props}
    />
  );
};

LineitemVideoPlaybackMethod.displayName = 'Lineitem.video.playbackMethod';

export { LineitemVideoPlaybackMethod };
