import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ControlledInputNumber } from 'components';

const LineitemGeneralMinimalCtrValue = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'general.minimalCtr.isActive',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(!isActive);
  }, [isActive]);

  return (
    <ControlledInputNumber
      name='general.minimalCtr.value'
      label='Value'
      min={0}
      max={100}
      step={0.01}
      isDisabled={isDisabled}
      addon='%'
      {...props}
    />
  );
};

LineitemGeneralMinimalCtrValue.displayName =
  'Lineitem.general.minimalCtr.value';

export { LineitemGeneralMinimalCtrValue };
