import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './devicebrands.consts';

export const fetchAll = createAsyncThunk(
  NAME + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/consts/devicebrands' });
    return response.data;
  }),
);
