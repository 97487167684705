import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { NAME } from './devices.consts';
import adapter from './devices.adapter';
import { fetchAll } from './devices.actions';

const slice = createSlice({
  name: NAME,
  initialState: {
    ...adapter.getInitialState(),
    status: DATA_UNDEFINED,
    errors: NO_ERRORS,
    current: NO_CURRENT_ENTITY,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAll.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
