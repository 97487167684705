import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './deals.adapter';
import { REDUCER_KEY } from './deals.consts';
import { DealParams } from './deals.types';
import { selectAllCampaigns } from 'store/campaigns';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): DealParams | null =>
  selectors.selectById(state, id) || null;

export const selectCurrentDealId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllDeals = createSelector(
  state => selectors.selectAll(state),
  finalizedDeals => finalizedDeals || [],
);

export const selectActiveDeals = createSelector(
  state => selectAllDeals(state),
  deals => deals.filter(deal => deal.status?.toLowerCase().includes('active')),
);

export const selectEndedeDeals = createSelector(
  state => selectAllDeals(state),
  deals => deals.filter(deal => deal.status?.toLowerCase().includes('ended')),
);

export const selectRequestedDeals = createSelector(
  state => selectAllDeals(state),
  deals =>
    deals.filter(deal => deal.status?.toLowerCase().includes('requested')),
);

export const selectPausedDeals = createSelector(
  state => selectAllDeals(state),
  deals => deals.filter(deal => deal.status?.toLowerCase().includes('paused')),
);

export const selectUnspecifiedDeals = createSelector(
  state => selectAllDeals(state),
  deals =>
    deals.filter(deal => deal.status?.toLowerCase().includes('unspecified')),
);

export const selectCurrentDeal = createSelector(
  state => selectCurrentDealId(state),
  state => selectAll(state),
  (currentDealId, allDeals) => allDeals.find(({ id }) => id === currentDealId),
);

export const selectDealsAsOptions = createSelector(
  selectAllDeals,
  (allDeals: DealParams[]) =>
    allDeals.map(
      ({ id, deal: { name } }: { id?: number; deal: { name?: string } }) => ({
        id,
        name,
      }),
    ),
);

export const selectActiveDealsAsOptions = createSelector(
  selectActiveDeals,
  (activeDeals: DealParams[]) =>
    activeDeals.map(
      ({ id, deal: { name } }: { id?: number; deal: { name?: string } }) => ({
        id,
        name,
      }),
    ),
);

export function selectDeal(
  state: RootState,
  dealId: number,
): DealParams | undefined {
  return selectors.selectById(state, dealId) || undefined;
}

export const selectCampaignsOfCurrentDeal = createSelector(
  selectCurrentDealId,
  selectAllCampaigns,
  (currentDealId, allCampaigns) =>
    allCampaigns.filter(campaign => campaign.advertiser === currentDealId) ||
    [],
);
