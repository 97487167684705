import * as React from 'react';
import { ControlledRadioGroup } from 'components/ControlledFormFields';

const VALUES = [
  { name: 'Target apps', id: true },
  { name: 'Exclude apps', id: false },
];

const LineitemTargetingAppPlacementTargetingIncludePlacements = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  return (
    <ControlledRadioGroup
      name='targeting.appPlacementTargeting.includePlacement'
      values={VALUES}
      valueType='bool'
      helper='Applies to both package names and targeting file'
      {...props}
    />
  );
};

LineitemTargetingAppPlacementTargetingIncludePlacements.displayName =
  'Lineitem.targeting.appPlacementTargeting.includePlacement';

export { LineitemTargetingAppPlacementTargetingIncludePlacements };
