import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import adapter, { initialState } from './targetings.adapter';
import {
  fetchAllTargetings,
  fetchById,
  create,
  update,
  remove,
  setCurrentTargeting,
} from './targetings.actions';
import { NAME } from './targetings.consts';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(NAME + '/upload', (state, action) => {
        state.uploadProgress = action.payload;
      })
      .addCase(fetchAllTargetings.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(fetchById.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(create.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(remove.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(setCurrentTargeting.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(NAME + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        (state, action) => {
          state.uploadProgress = 0;
          readyActionHandler(state, action);
        },
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        (state, action) => {
          state.uploadProgress = 0;
          rejectActionHandler(state, action);
        },
      );
  },
});

export const { reducer } = slice;
