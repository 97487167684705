import React from 'react';
import { ControlledInputText } from 'components';

export const CampaignAdvertiserDomain = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputText
    name='advertiserDomain'
    label='Advertiser Domain'
    {...props}
  />
);
